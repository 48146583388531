/* NEW CSS */

.StatusCover{
        display: flex;
        justify-content:space-between;
        align-items: center;
        gap: 1rem;
     

}

.StaCont{
        width: 100%;
        background-color: white;
        height: 250px;
        border-radius: 20px;
        padding: 20px;

}


/* NEW CSS ENDS HERE */



.ModalBackground{
        background-color: #F4F4F7;
}
.Clique24__ModalHeadings{
        display: flex;
        justify-content: end;
        align-items: center;
}

/* .UserSearchWrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
} */
.InputSearchBar[type="text"]{
        width: 100px;
        background-color: transparent;
        transition: width 0.5s ease-in-out;
        height: 60px;
        padding: 20px;
        border-radius: 50px;
}

.InputSearchBar[type="text"]:focus {
        width: 100%;
        outline: 1px solid gray;
       
}
.SearchCont{
        width: 200px;
}
.UserSubHeading{
       
        width: 300px;
}
.InpuSearchInput{
        width: 100%;
        /* width: 100%; */
        /* background-color: red; */
        border: none;
        height: 50px;
        color: gray;
        font-family: 'Urbanist', sans-serif;
        font-weight: 500;
        font-size: 15px;
        letter-spacing: 0.5px;
        border-radius: 10px;
        padding: 10px;
       
}


.InpuSearchInput:focus {
      border: none;
      outline: none;

       
}
.SearchUser{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 2px solid  #F4F4F7;
        border-radius: 50px ; 
        padding: 10px 10px 10px 10px;
     


}
.SideImg{
        width: 100px;
        height: 100px;
       
}

.SearchUserCont{
      
        width: 80%;
        padding: 0px 0px 0px 10px;
        
    
}
/* .SearchUserBtn{
        height: 50px;
        width: 20%;
        background-color: black;

}   */

.SearchUserBtnCont{
        background: none;
      
        height: 50px;
      
        

}

.SearchIcon{
        font-size: 30px !important;
        color: gray;


}



.InfoHeadings{
       
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 800;
        font-size: 17px;
        letter-spacing: 0.5px;
}
.InfoHContainer{

        display: flex;
        flex-direction: column;
        gap: 1rem;
}

.AvaterCont{
        display: flex;
        align-items: center;
        justify-content: space-between;
}
.EmailCont{
        text-align: end;
}
.PersonalInfoContainer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        
}
.CustomerFraudSta{
        text-align: end;
}
.CustomerStatusContainer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;

}
.SupportIcon{
        color: gray;
        font-weight: 800;
        font-size: 17px;
        cursor: pointer;

}

.Flaged{

        color: red;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 15px;
        letter-spacing: 0.5px;

}
.SupportContainer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;

}
.StatusDropDown{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

}

.CustomerName60{
        color: gray;
        font-family: 'Urbanist', sans-serif;
        font-weight: 800;
        font-size: 20px;
        letter-spacing: 0.7px;

}
.CustomerName30{
        color: rgb(41, 156, 6);
        font-family: 'Urbanist', sans-serif;
        font-weight: 800;
        font-size: 15px;
        letter-spacing: 0.5px;

}
.CustomerName{
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 800;
        font-size: 15px;
        letter-spacing: 0.7px;

}
.CustomerUserName{
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 13px;
        letter-spacing: 0.5px;

}
.UserId{
        color: gray;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.5px;

}




.TransactionWalletHeadings{
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.5px;

}

.TransactionNumber{
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.5px;

}
.TransCount{
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.5px;

}
.WalletAmt20{
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 15px;
        letter-spacing: 0.5px;

}
/* TABLE CSS */
.Members-Displays-Details2{
       
        background-color: #e7e7e7;
        padding: 5px 5px ;
        font-size: 12px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 500;
        border-radius: 50px !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 7px;
        letter-spacing: 0.5px;

}
.Wallet-Amts{
        max-width: 150px;
        width: 150px;
        display: inline-block;
        padding-left: 14px;
       
       

        }

.Wallet-Initials{
        max-width: 150px;
        width: 150px;
        display: inline-block;
        padding-left: 14px;
       

}

.Wallet-Expected{
        max-width: 150px;
        width: 150px;
        display: inline-block;
        padding-left: 14px;

}
.Wallet-Ref{
        max-width:200px;
        width: 200px;
        display: inline-block;
        padding-left: 14px;
     
        /* margin: 5px; */
        

}
.Wallet-Ref-Heading{
        font-size: 12px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        
}

.Wallet-Ref-Result{
        font-size: 11px;
        color: rgb(68, 68, 68);
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        display: flex;

                
          }.Wallet-Flow{
                max-width: 70px;
                width: 70px;
                display: inline-block;
             
        
                }
        .Wallet-Type{
                max-width: 70px;
                width: 70px;
                display: inline-block;
                 
        
        }
        .Wallet-Mode{
                max-width: 110px;
                width: 110px;
                display: inline-block;
                
        
        }
        .Wallet-Date{
                max-width: 170px;
                width: 170px;
                display: inline-block;
              
        
        }
        
        .Wallet-Status{
                max-width: 70px;
                width: 70px;
                display: inline-block;
               
        
        }
        
        .Members-Comp-Total-Cont{
                display: flex;
                justify-content: space-between;
                align-items: center;
        
        }
        .Members-Comp-Total{
                font-size: 12px;
                color: black;
                font-family: 'Urbanist', sans-serif;
                font-weight: 600;
                letter-spacing: 0.5px !important;
                display: flex;
        
        }

/* TABLE CSS ENDS HERE */

.Display-Container4{
        margin-top: 20px;
        margin-bottom: 20px;

}






/* THIS IS THE OLD CSS */
.Back__Imgage__Cont{
        max-width: 600px;
        margin: auto;
}


.Back__Imgage{
        
        height: 700px;
        max-width: 600px;
        margin: auto;
        position: absolute;
     
}
.Settings__Modal__Container{
        max-width: 600px;
        margin: auto;
        z-index: 1;
        padding: 50px 0px;   
}
.Settings__Modal__Container80{
        max-width: 400px;
        margin: auto;
        z-index: 1;
        padding: 20px 0px;   
}

.Settings__Modal__Container33{
        max-width: 400px;
        margin: auto;
        z-index: 1;
        padding: 50px 0px;   
}
.Settings__Modal__Container22{
        max-width: 400px;
        margin: auto;
        z-index: 1;
        padding: 20px 0px;   
}

.Donate-Cover-wrapper{
        background-color: #e7e7e7;
        padding: 20px;
        margin-bottom: 10px;
        border-radius: 20px;
}
.Settings__Modal__Container343{
        max-width: 450px;
        width: 450px;
        margin: auto;
        z-index: 1;
        padding: 10px 0px;   
       
}
.Profile__Cont03{
        display: flex;
        align-items: center;
        border-left: 4px solid black;
        padding-left: 10px;
}
.Avater__Img__Modal02{
        width: 40px;
        height: 40px;
        border-radius: 50%;
}

.Start-End-dates{

        font-family: 'Urbanist', sans-serif;
        font-weight: 500;
        font-size: 12px;
        color: black;
        letter-spacing: 0.5px;
        
}

.Avater__Img__Modal0200{
        width: 60px;
        height: 60px;
        border-radius: 50%;

}
.Settings__Modal__Container2{
        max-width: 700px;
        margin: auto;
        z-index: 1;
        padding: 50px 0px;   
}


.Package__Container{
        max-width: 1500px;
        margin: auto;
        z-index: 1;
      
}

.Package__Contain{
      
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
}
.Package__Header__Cont{
        display: flex;
        justify-content: space-between;
        align-items: center;
}

.Clique24__Close{
        color:  black;
        font-size: 25px !important;
        cursor: pointer;
}

.Date-Founded{
        font-family: 'Urbanist', sans-serif;
        color: black !important;
        font-weight: 500 !important;
        font-size: 12px !important;
        letter-spacing: 0.5px;

}
.Found-Date{
        font-family: 'Urbanist', sans-serif;
        color: black !important;
        font-weight: 500 !important;
        font-size: 12px !important;
        letter-spacing: 0.5px;

}
.Clique24__Headings{
        display: flex;
        align-items: center;
       
}

.Inv-Heading{
        display: flex;
        align-items: center;
       
}

.Clique24__Headings29{
        
        padding-bottom: 20px;
}

.Clique24__Headings__Note{
        margin-left: 10px;
}

.Log__Cont{
        width: 40px;
        height: 40px;
}

.AlertNot{
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 13px;
         color: red;
         letter-spacing: 0.5px;

}

.Log__Img__Modal{
        width: 40px;
        height: 40px;
}

.Log__Cont1{
        width: 150px;
        height: 40px;
}


.Log__Img__Modal1{
        width: 120px;
        height: 30px;
}
.sms-notification{
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 12px;
        color: black;
        letter-spacing: 0.5px;

}
.Clique24__Settings__Header{
        font-family: 'Urbanist', sans-serif;
        font-weight: 800;
        font-size: 23px;
        color: black;
        letter-spacing: 0.5px;
}

.Clique24__Settings__Note{
       font-family: 'Urbanist', sans-serif;
        font-weight: 500;
        font-size: 12px;
        color: rgb(61, 61, 61);
        letter-spacing: 0.5px;
}
.Settings__Headings{
       font-family: 'Urbanist', sans-serif;
        color: gray !important;
        font-weight: 600 !important;
        font-size: 12px;
        letter-spacing: 0.5px;
}
.Profile__Cont29{
        display: flex;
        justify-content: center;
        letter-spacing: 0.5px;
       
}
.Name-Center{
        font-family: 'Urbanist', sans-serif;
        color: black !important;
        font-weight: 600 !important;
        font-size: 12px;
        letter-spacing: 0.5px;

}
.Approval-amt{
        font-family: 'Urbanist', sans-serif;
        color: black !important;
        font-weight: 600 !important;
        font-size: 16px;
        letter-spacing: 0.5px;

}
.Link-Box{
        background-color: #faf5eb;
        padding: 0px 10px  ;
        overflow: hidden;
}
.Total-Box{
        font-family: 'Urbanist', sans-serif;
        color: black !important;
        font-weight: 600 !important;
        font-size: 12px;
        letter-spacing: 0.5px;
        height: 30px;
        line-height: 30px;

        

}

.Total-Box2{
        display: flex;  
        align-items: center;
     

}
.Box-search{
      display: flex;  
      align-items: center;
    

}
.Member-SearchesInput22{
        width: 290px !important;
        border-bottom-left-radius: 20px !important;
        border-top-left-radius: 20px !important;
        height: 30px;
        line-height: 30px;
        font-family: 'Urbanist', sans-serif;
        color: black !important;
        font-weight: 400 !important;
        font-size: 11px !important;
        letter-spacing: 0.5px;
}
.Member-Searchesbtn24{
        background-color: black;
        color: gray;
        width: 70px;
        height: 30px;
        letter-spacing: 0.2px;
        font-size: 13px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 800;
        text-align: center;
        line-height: 30px;
        border-top-right-radius: 50px !important;
        border-bottom-right-radius: 50px !important;
        cursor: pointer;
        letter-spacing: 0.5px;
}
.Image-C{
        height: 60px;
        width: 60px;
        border-radius: 50%;
}
.Image-Conts{
        display: flex;
        align-items: center;

}
.Donor-Count-Heading{
        font-family: 'Urbanist', sans-serif;
        color: black !important;
        font-weight: 600 !important;
        font-size: 12px;
        letter-spacing: 0.5px;
      
        

}

.Im-Conts{
        display: flex;
        align-items: center;

}
.Donor-Count{
        display: flex;
        align-items: center;
        margin-top: 10px;

}

/* .Avater__Img__Modal0299{
        width: 50px;
        height: 50px;
        border-radius: 50%;

} */
.Donation-Name-Cont{
        display: flex;
        align-items: center;
        justify-content: space-between;
        
}
.Donation-Name{
        font-family: 'Urbanist', sans-serif;
        color: black !important;
        font-weight: 400 !important;
        font-size: 17px;
        letter-spacing: 0.5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; 
        
      
        

}
.Createdby-Name{
        font-family: 'Urbanist', sans-serif;
        color: black !important;
        font-weight: 600 !important;
        font-size: 11px;
        letter-spacing: 0.5px;
        
        

}
.Target-Amt{
        font-family: 'Urbanist', sans-serif;
        color: black !important;
        font-weight: 600 !important;
        font-size: 12px;
        letter-spacing: 0.5px;

}

.Target-raised-box{
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
}

.Donation-Wrapper{
       margin-bottom: 20px;
}
.Donation-bar{
        margin-bottom: 20px;

}
.Donor-C1{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 2px solid white; 
        text-align: center;
        line-height: 50px !important;
        
        
        }
.Donor-C{
width: 40px;
height: 40px;
border-radius: 50%;

border: 2px solid white;
margin-left: -13px;
text-align: center;
line-height: 50px !important;


}

.Des-cover{
        margin-top: 10px;
      
}
.Donor-Counting{
        margin-left: 5px;
        cursor: pointer;
        font-family: 'Urbanist', sans-serif;
        color: black !important;
        font-weight: 400 !important;
        font-size: 15px;
        letter-spacing: 0.5px;

}
.Link-Edit-share{
        font-family: 'Urbanist', sans-serif;
        color: black !important;
        font-weight: 600 !important;
        font-size: 12px;
        letter-spacing: 0.5px;

}
.Donation-dates{
        font-family: 'Urbanist', sans-serif;
        color: black !important;
        font-weight: 600 !important;
        font-size: 12px;
        letter-spacing: 0.5px;
        text-align: end;

}
.Donation-dates-status{
        font-family: 'Urbanist', sans-serif;
        color: gray !important;
        font-weight: 600 !important;
        font-size: 12px;
        letter-spacing: 0.5px;

}
.Name-Center2{
        font-family: 'Urbanist', sans-serif;
        color: gray !important;
        font-weight: 600 !important;
        font-size: 11px;
        letter-spacing: 0.5px;
}
.Description-Header{
        font-family: 'Urbanist', sans-serif;
        color: black !important;
        font-weight: 600 !important;
        font-size: 13px;
        letter-spacing: 0.5px;

}
.Donate-Con{
        display: flex;
        justify-content: space-between;
        align-items: center;

}
.Donate-Details{
        max-width: 450px;
        width: 450px;
        background-color: #efefef;
        padding: 20px;
        border-radius: 20px;
        overflow: hidden;

}
.Description-N{
        font-family: 'Urbanist', sans-serif;
        color: black !important;
        font-weight: 500 !important;
        font-size: 12px;
        letter-spacing: 0.5px;
        height: 70px;
        line-height: 15px;
        

}
.Img-Bx-Donation{
        max-width: 100px;
        width: 100px;
        height: 100px;
        
}
.Img-Bx-Donation1{
        max-width:100px;
        width: 100px;
        height: 200px;
       
}
.Username-Center{
        text-align: center;
        margin-top: 10px;
}

.Username-Center22{
        text-align: center;
        margin-top: 30px;
        margin-bottom: 30px;

}
.Profile__Cont202{
        justify-content: center;

}
.Profile__Wrap{
        display: flex;
        align-items: center;
}
.Created-Date-By{
        display: flex;
        justify-content: space-between;
        align-items: center;


}

.Profile__Cont{
        /* display: flex;
        justify-content: space-between;
        align-items: center; */
        border-bottom: 1px solid rgb(243, 235, 215);
        letter-spacing: 0.5px;
        padding-bottom: 20px;
           
}
.Profile__Cont02{
        
        border-bottom: 1px solid rgb(243, 235, 215);
        height: 100px;
}
.Profile__Cont__Wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
}

.Profile__Cont__Wrapper42{
        display: flex;
        align-items: center;
}



.Profile__Cont__Wrapper1{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background-color: #e7e7e7;
        padding: 20px ;
        border-radius: 20px;
       
}
.Profile__Cont__Wrapper20{
        width: 500px;
       
}
.Profile__Cont__Wrapper15{
        width: 500px;
      
       
}


.Profile__Cont__Wrapper12{
        width: 500px;
       
}

.Avater__Img__Modal{
        width: 70px;
        height: 70px;
        cursor: pointer;
}

.Profile__Cont__Names{
        margin-left: 10px;

}

.Profile__Cont__Names34{
      
        display: flex !important;
        justify-content: space-between;
       
        padding-bottom: 20px;
        border-bottom: 1px solid #f8e4bd;

}
.Profile__Cont__Names01{
        width: 500px;
        background-color: red;
        display: flex;
        justify-content: space-between;
        align-items: center;
}
.DisplayPackageAmount{
       font-family: 'Urbanist', sans-serif;
        color: black !important;
        font-weight: 600 !important;
        font-size: 20px;
        letter-spacing: 0.5px;

}
.PackAmt{
       font-family: 'Urbanist', sans-serif;
        color: gray !important;
        font-weight: 400 !important;
        font-size: 12px;
        letter-spacing: 0.5px;

}
.DisplayNamesCont{
        display: flex;
        justify-content: space-between;
        align-items: center;
       

}

.DisplayNames101{
        font-family: 'Urbanist', sans-serif;
        color: black !important;
        font-weight: 600 !important;
        font-size: 13px;
        letter-spacing: 0.5px;

}
.DisplayNames{
        font-family: 'Urbanist', sans-serif;
        color: black !important;
        font-weight: 600 !important;
        font-size: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; 
        letter-spacing: 0.5px;
        max-width: 400px;
     
}
.DisplayNames26{
        font-family: 'Urbanist', sans-serif;
        color: black !important;
        font-weight: 800 !important;
        font-size: 16px;
        letter-spacing: 0.5px;
        max-width: 400px;
     
}
.DisplayNames2{
       font-family: 'Urbanist', sans-serif;
        color: gray !important;
        font-weight: 500 !important;
        font-size: 12px;
        letter-spacing: 0.5px;
}

.DisplayNames2__Container{
        display: flex;
        justify-content: space-between;
        align-items: center;
}

.DisplayNames4__Container{
        display: flex;
        justify-content: space-between;
        align-items: center;
}

.DisplayUsernames4{
        font-family: 'Urbanist', sans-serif;
        color: black !important;
        font-weight: 400 !important;
        font-size: 12px;
        letter-spacing: 0.5px;
}

.DisplayUsernames101{
        font-family: 'Urbanist', sans-serif;
        color: gray !important;
        font-weight: 400 !important;
        font-size: 12px;
        letter-spacing: 0.5px;

}
.DisplayUsernames30{
        font-family: 'Urbanist', sans-serif;
        color: gray !important;
        font-weight: 600 !important;
        font-size: 12px;
        letter-spacing: 0.5px;
        padding: 0px 0px;
       
        width: 100%;
        max-width: 500px;
        height: 100px !important;


}
.DisplayUsernames{
        

        font-family: 'Urbanist', sans-serif;
        color: gray !important;
        font-weight: 600 !important;
        font-size: 12px;
        letter-spacing: 0.5px;

}
.DisplayUsernames1{
        font-family: 'Urbanist', sans-serif;
        color: black !important;
        font-weight: 500 !important;
        font-size: 12px; 
        padding-bottom: 20px;
        max-width: 100%;
        letter-spacing: 0.5px;
       
}

.DisplayNames__Cont{
        display: flex;
        justify-content: space-between;
        align-items: center;
       
}
.Back-Move{
        display: flex;
        align-items: center;
        justify-content:space-between;
}

.Limit-Count{
        font-family: 'Urbanist', sans-serif;
        color: black !important;
        font-weight: 300 !important;
        font-size: 17px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; 
        letter-spacing: 0.5px;
       

}


.Buy-More-box{
        cursor: pointer;
       
}
.Limit-box{
        background-color: #e7e7e7;
        padding: 20px;
        border-radius: 10px;
        height: 400px;
        display: flex;
        flex-direction: column;
}


.Limit-box22{
        background-color: #e7e7e7;
        padding: 20px;
        border-radius: 10px;
        /* height: 400px; */
        display: flex;
        flex-direction: column;

}

.LM:nth-of-type(2){
        margin-top: auto;
  
  
  }
  .LM{
        display: flex;
        flex-direction: column;
  }

.Limit-Amt{
        font-family: 'Urbanist', sans-serif;
        color: black !important;
        font-weight: 400 !important;
        font-size: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; 
        letter-spacing: 0.5px;

}

.Upgrade-list{
        margin-top: 10px;
        letter-spacing: 0.5px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600 !important;
        font-size: 12px !important;
        color: black;

}
.Profile__Cont__Saves{
        border: none; 
        background-color: black; 
        color: #F7AD27;
        padding: 10px 30px;
        
        text-align: center;
        border-radius: 50px;
        letter-spacing: 0.5px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 500 !important;
        font-size: 15px !important;
        cursor: pointer;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
       

}

.Profile__Cont__Saves3{
        border: none; 
        background-color: gray; 
        color: black;
        padding: 10px 30px;
        
        text-align: center;
        border-radius: 50px;
        letter-spacing: 0.5px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 500 !important;
        font-size: 15px !important;
        cursor: pointer;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
       

}
.Package__List__Order{
        margin-top: 10px;
}
.Profile__Special{
       font-family: 'Urbanist', sans-serif;
        font-weight: 600 !important;
        font-size: 13px !important;
        color: gray;
        margin-top: 10px;
        letter-spacing: 0.5px;
       

}
.Mobile__View__Proceed1{
        display: none;
        background-color: black;
        color: #F7AD27;
        padding: 5px;
        width: 100px;
        text-align: center;
        border-radius: 50px;
        letter-spacing: 0.5px;
       font-family: 'Urbanist', sans-serif;
        font-weight: 500 !important;
        font-size: 13px !important;
        cursor: pointer;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.Profile__Cont__Saves1{
        background-color: black;
        color: #F7AD27;
        padding: 5px;
        width: 100px;
        text-align: center;
        border-radius: 50px;
        letter-spacing: 0.5px;
       font-family: 'Urbanist', sans-serif;
        font-weight: 500 !important;
        font-size: 13px !important;
        cursor: pointer;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
       

}
.Profile__Pro{
        margin-top: 20px;
        background-color: black;
        color: #F7AD27;
        padding: 5px;
        width: 100px;
        text-align: center;
        border-radius: 50px;
        letter-spacing: 0.5px;
       font-family: 'Urbanist', sans-serif;
        font-weight: 500 !important;
        font-size: 13px !important;
        cursor: pointer;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.Profile__Cont__Saves2{
        background-color: #00a70e;
        color: Black;
        padding: 5px;
        width: 100px;
        text-align: center;
        border-radius: 50px;
       font-family: 'Urbanist', sans-serif;
       letter-spacing: 0.5px;
        font-weight: 500 !important;
        font-size: 13px !important;
        cursor: pointer;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.Input__User{
        width: 100% !important;
        height: 40px;
        background-color: #faf5eb !important;
        font-family: 'Urbanist', sans-serif;
        font-weight: 500 !important;
        font-size: 13px !important;
        color: gray;
        letter-spacing: 0.5px;
}
.Input__UserName2{
        /* width: 100% !important; */
        height: 40px;
        background-color: #faf5eb !important;
        font-family: 'Urbanist', sans-serif;
        font-weight: 500 !important;
        font-size: 13px !important;
        color: gray;
        letter-spacing: 0.5px;
}
.Input-User-Clique{
        /* width: 300px !important; */
        height: 40px;
        background-color: #faf5eb !important;
        font-family: 'Urbanist', sans-serif;
        font-weight: 500 !important;
        font-size: 13px !important;
        color: gray;
        letter-spacing: 0.5px;

}
.Input__User20{
        letter-spacing: 0.5px;
        width: 50px !important;
        height: 40px;
        background-color: #faf5eb !important;
       font-family: 'Urbanist', sans-serif;
        font-weight: 500 !important;
        font-size: 13px !important;
        color: gray;
}

.input-group20{
        width: 5220px !important;
        background-color: red;

}


.Card__Deals{
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 100%;
       

}
.Clique24__Close34{
        text-align: end;
}
.Input__User04{
        height: 40px;
        background-color: #faf5eb !important;
       font-family: 'Urbanist', sans-serif;
        font-weight: 500 !important;
        font-size: 13px !important;
        color: gray; 
        letter-spacing: 0.5px;
}
.Input__User1{
        width: 140px !important;
        height: 40px;
        background-color: #faf5eb !important;
        font-family: 'Urbanist', sans-serif;
        font-weight: 500 !important;
        font-size: 13px !important;
        color: gray;
        letter-spacing: 0.5px;

}
.Input__User2{
        width: 100% !important;
        height: 40px;
        background-color: #faf5eb !important;
       font-family: 'Urbanist', sans-serif;
        font-weight: 500 !important;
        font-size: 13px !important;
        color: gray;
        letter-spacing: 0.5px;

}

.Input__User34{
        width: 100% !important;
        /* height: 150px; */
        /* background-color: #faf5eb !important; */
        font-family: 'Urbanist', sans-serif;
        font-weight: 500 !important;
        font-size: 14px !important;
        color: black;
        letter-spacing: 0.5px;

}


.Input__User36{
        width: 100% !important;
        height: 200px;
        background-color: #faf5eb !important;
       font-family: 'Urbanist', sans-serif;
        font-weight: 500 !important;
        font-size: 13px !important;
        color: gray;
        letter-spacing: 0.5px;

}
.Card__Details__Display{
        display: flex;
        justify-content: space-between;
        align-items: center;
}
.Selection__Cont{
        display: flex;
        justify-content: space-between;
        align-items: center;
}


.Cliques__Cont__Don__Heading{
        letter-spacing: 0.5px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 500 !important;
        font-size: 13px !important;
        color: black;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; 
        width: 350px;
       
}

.Cliques__Cont__Don{
        margin-left: 10px;
}

.Cliques__Cont__Don__Amount{
        letter-spacing: 0.5px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 500 !important;
        font-size: 12px !important;
        color: gray;
}

.Cliques__Cont__Don__Dura{
        letter-spacing: 0.5px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 400 !important;
        font-size: 11px !important;
        color: gray;
}

.Profile__Cont__Wrapper4{
     
        border-bottom: 1px solid #f8e4bd;
        padding-bottom: 30px;
        margin-top: 20px;
       
}

.Profile__Cont__Wrapper44{
     
       
        display: flex;
        
        align-items: center;
        padding-bottom: 30px;
        margin-top: 20px;
       
}

.List__Numbers{
        display: flex;
        justify-content: space-between;
        align-items: center;
}

.List__Numbers__Heading{
        letter-spacing: 0.5px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 500 !important;
        font-size: 12px !important;
        color: black;
        display: flex;
        justify-content: space-between;
        align-items: center;
}
.List__Numbers__Selectall{
        cursor: pointer;
}

.List__Numbers__Selectall:hover{
        cursor: pointer;
        color: #313131;
}
.List__Numbers__Heading{
        letter-spacing: 0.5px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 500 !important;
        font-size: 12px !important;
        color: black;
}

.Payment__Heading{
        letter-spacing: 0.5px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600 !important;
        font-size: 14px !important;
        color: gray;
}

.Payment__Heading__Cont{
        border-bottom: 1px solid #f8e4bd;
        padding-bottom: 30px;
}

.Card__Details__Show{
        letter-spacing: 0.5px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 400 !important;
        font-size: 12px !important;
        color: gray;

}

.Card__Details__Show1{
        letter-spacing: 0.5px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 400 !important;
        font-size: 12px !important;
        color: gray;
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; 

}
.KYC__Steps{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
}

.KYC__Steps1{
        background-color: #F7AD27;
        color: black;
        width: 60px;
        height: 60px;
        text-align: center;
        line-height: 60px;
        border-radius: 50%;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600 !important;
        font-size: 12px !important;
        color: gray;
        letter-spacing: 0.5px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        

}

.KYC__Line{
        width: 340px;
        height: 5px;
        font-family: 'Urbanist', sans-serif;
        letter-spacing: 0.5px;
        font-weight: 600 !important;
        font-size: 15px !important;
        color: black;
        text-align: center;
        line-height: 5px;
        border-bottom: 1px dotted #F7AD27;

}
.KYC__StepsOne__Cont{
        letter-spacing: 0.5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-top: 1px solid gray;
        padding-top: 10px;
}
.KYC__StepsOne{
        letter-spacing: 0.5px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600 !important;
        font-size: 13px !important;
        color: gray;
        

}
.UserTransaction__Cont{
        display: flex;
        justify-content: space-between;
        align-items: center;
}
.UserTransaction__Cont2{
        display: flex;
        align-items: center;
        margin-top: 20px;
}
.UserTransaction__Cont__Close{
        display: flex;
        justify-content: end;
        align-items: center;
}

.Your__Transaction{
       
        border-left: 2px solid black;
        padding: 0px 0px 0px 20px;
}
.DontionImgBox{
       background-color: red;
        height: 170px;
        width: 50%;
        
}

.CreatedBy{
        letter-spacing: 0.5px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600 !important;
        font-size: 12px !important;
        color: gray;
       
      


}
.Select-Options{
        display: flex;
     
        align-items: center;

}
.DontionImgBox2{
      
        height: 170px;
        width: 170px;
        
}
.User__Transactions{

        padding-bottom: 20px;
        margin: 40px auto;
        max-width: 800px;
   
}
.User__Transactions__ViewDetails{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        
        
}
.CheckBox-month-Heading-Ul{
        background-color: none;
}
.CheckBox-month-Heading{
        letter-spacing: 0.5px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600 !important;
        font-size: 12px !important;
        color: black;

}
.CheckBox-month{
        display: flex;
        align-items: center;

}
.Cont-wrapper{
        background-color: #e7e7e7;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        margin-bottom: 20px;
        
}

.User__Transactions__ViewDetails2{
      
        height: 100px !important;
        
        
}
.User__Transactions__View{
        padding-bottom: 20px;
        margin: 40px auto;
        max-width: 800px;
      
      
}

.User__Transactions__View22{
        padding-bottom: 20px;
        margin: 20px auto;
        max-width: 500px;
   
        
      
}
.User__Transactions__View2{
        padding-bottom: 20px;
        margin: 40px auto;
        max-width: 500px;
   
        
      
}
.Transaction__Align{
    text-align: right;
    align-items: center;
   
}
.Sender__Img__Cont{
        width: 50px;
        height: 50px;
}
.UserAvater12{
        width: 50px;
        height: 50px;
        border-radius: 50%;

}
.Sender__Img{
        display: flex;
        align-items: center;
        
}
.Sender__Usernames{
        margin-left: 10px;
}

.User__Transactions__ViewHeadings{
        letter-spacing: 0.5px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600 !important;
        font-size: 13px !important;
        color: black;
        padding-bottom: 10px;
        margin-bottom: 20px;
        border-bottom: 2px solid #F7AD27;
       
      

}

.Trans__Headings{
        letter-spacing: 0.5px;
       font-family: 'Urbanist', sans-serif;
        font-weight: 600 !important;
        font-size: 14px !important;
        color: gray;
        padding-bottom: 20px;
        
}

.Cliqueshare{
        letter-spacing: 0.5px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600 !important;
        font-size: 13px !important;
        color: black;
        cursor: pointer;


}
.Clique24__Share{
        font-size: 20px !important;
        color: black;
        cursor: pointer;
        
}


.CliqueHeader1{
        letter-spacing: 0.5px;
       font-family: 'Urbanist', sans-serif;
        font-weight: 800;
        font-size: 14px;
        background-color: black;
        color: #F7AD27;
        padding: 10px 0px 10px 0px;
       
  }
  .Outflow__Inflow__Cont{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;


  }


  .Outflow__Inflow{
        width: 380px;
        padding: 10px 10px 10px 10px;
        background-color:#F7AD27;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .Outflow__Inflow__Not{
        background-color: black;
        width: 60px;
        height: 60px;
        justify-content: center;
        border-radius: 10px;
        text-align: center;
        line-height: 60px;

  }
.Outflow__Not{
        color: #F7AD27;
        font-size: 25px !important;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.Outflow__Inflow__Heading{
        letter-spacing: 0.5px;
       font-family: 'Urbanist', sans-serif;
        font-weight: 500;
        font-size: 13px !important;
        color: black;
}

.Outflow__Inflow__Tille{
        letter-spacing: 0.5px;
       font-family: 'Urbanist', sans-serif;
        font-weight: 500;
        font-size: 14px !important;
        color: gray;
}

.Search__Transaction__Cont{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5px;
}

.Search__Search{
        width: 380px;
        padding: 10px 10px 10px 10px;
        background-color:#f8e4bd;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.Search__Search1{
        width: 380px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
       
        
        
        
        
}
.Search__Search10{
        
        width: 180px;
        padding: 10px 10px 10px 10px;
        background-color:#f8e4bd;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.Input__Search{

        letter-spacing: 0.5px;
        width: 400px !important;
        height: 40px;
        background-color: #faf5eb !important;
       font-family: 'Urbanist', sans-serif;
        font-weight: 500 !important;
        font-size: 13px !important;
        color: gray;

}

.Input__Date{
        letter-spacing: 0.5px;
        width: 160px !important;
        height: 40px;
        background-color: #faf5eb !important;
       font-family: 'Urbanist', sans-serif;
        font-weight: 500 !important;
        font-size: 13px !important;
        color: gray;

}

.Search__Btn{
        letter-spacing: 0.5px;
        background-color: black!important;
        color: #ffca27 !important;
       font-family: 'Urbanist', sans-serif;
        font-weight: 500 !important;
        font-size: 12px !important;


}

.Total__Cont{
        letter-spacing: 0.5px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
       font-family: 'Urbanist', sans-serif;
        font-weight: 500 !important;
        font-size: 13px !important;
        color: black;
}


.Transaction__Details__Display{
        letter-spacing: 0.5px;
        max-width: 100%;
        background-color: #efefef;
        padding: 0px 10px;
        border-radius: 50px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 400 !important;
        font-size: 11px !important;
        color: black;
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        

}

.Transaction__Ref{
        max-width: 200px;
        width: 200px;
        display: inline-block;
        margin: 5px;
      
}

 .Transaction__Amount{
        max-width: 100px;
        width: 100px;
       
        display: inline-block;
        margin: 5px;
      }

.Transaction__Flow{
        max-width: 100px;
        width: 100px;
     
        display: inline-block;
        margin: 5px;
}

.Transaction__Date{
        max-width: 200px;
        width: 200px;
        
        display: inline-block;
        margin: 5px;
}


.Transaction__Status{
        max-width: 60px;
        width: 60px;
       
        display: inline-block;
        margin: 5px;
}

.Transaction__Action{
        letter-spacing: 0.5px;
        max-width: 80px;
        width: 80px;
        margin: 5px;
        display: flex;
        align-items: center;
        justify-content: end;
        border: none;
       font-family: 'Urbanist', sans-serif;
        font-weight: 500 !important;
        font-size: 12px !important;
        color: black;
}

.View__Details{
        letter-spacing: 0.5px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600 !important;
        font-size: 11px !important;
        color: gray; 
        cursor: pointer;
}
.Trans__Heading{
        letter-spacing: 0.5px;
       font-family: 'Urbanist', sans-serif;
        font-weight: 600 !important;
        font-size: 12px !important;
        color: black; 

}
.Trans__Tittle{
        letter-spacing: 0.5px;
       font-family: 'Urbanist', sans-serif;
        font-weight: 500 !important;
        font-size: 11px !important;
        color: rgb(80, 79, 79); 
        

}

.Disclaimer__Note{
        letter-spacing: 0.5px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 500 !important;
        font-size: 10px !important;
        color: rgb(80, 79, 79); 
        padding-bottom: 40px;
}

.Package__Conts{
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1000px;
        margin: 0 auto;
}

.Packages{
     
     
        margin-top: 40px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 20px;
        padding: 20px !important;

        
        /* background-color: red; */
        

}

.Close__Cont2{
        letter-spacing: 0.5px;
        display: flex;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600 !important;
        font-size: 13px !important;
        color: rgb(80, 79, 79); 
        align-items: center;

}
.Package__Header{
        letter-spacing: 0.5px;
        background-color: #F7AD27;
        color: black;
        width: 150px;
        padding: 5px;
        margin-bottom: 30px;
        border-radius: 50px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600 !important;
        font-size: 14px !important;
        text-align: center;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.Package__Amt{
        letter-spacing: 0.5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: black;
        padding: 10px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600 !important;
        font-size: 20px !important;
        margin-bottom: 10px;
      

}
.Mobile__View__Proceed{
        display: none;
}
.Package__Lifetime{
        color: rgb(116, 116, 116);
        letter-spacing: 0.5px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 400 !important;
        font-size: 13px !important;
       
}

.Package__RegCont{
        display: flex;
        justify-content: space-between;
        align-items: center;

}

.Package__Reg-Header{
        letter-spacing: 0.5px;
        color: black;
        padding: 10px 0px;
        margin-bottom: 10px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 300 !important;
        font-size: 20px !important;

}



.Package__Desc{
        letter-spacing: 0.5px;
        color: black;
        padding: 10px 0px;
        margin-bottom: 10px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600 !important;
        font-size: 12px !important;
        text-align: center;
        border-bottom: 1px solid rgb(241, 226, 90);
        border-top: 1px solid rgb(241, 226, 90);
}




.Package__li{
        letter-spacing: 0.5px;
        color: black;
        padding: 3px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600 !important;
        font-size: 12px !important;
}

.Log__Img__Modal02{
        width: 30px;
        height: 30px;
}
.Membership-Headers{
        letter-spacing: 0.5px;
        color: gray;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600 !important;
        font-size: 14px !important;
        margin-bottom: 20px;

}
.Membership-Cover{
      
        padding-bottom: 10px;
        border-bottom: 1px solid gray;
        margin-top: 30px;
}

.Membership-Cover04{
      
        padding-bottom: 10px;
      
        margin-top: 30px;
}


.Membership-Cover2{
      
        
        border-bottom: 1px solid gray;
        margin-top: 30px;
}

/* .Membership-Cover02{
      
        
        border-bottom: 1px solid gray;
        margin-top: 30px;
} */

.Membership-Status-Cont{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
}
.Membership-Status-Cont2{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0px;
}
.Membership-Cover-cont01{
        display: flex;
        flex-direction: column;
        text-align: end;
}

.Membership-status-tittle{
        letter-spacing: 0.5px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600 !important;
        font-size: 12px !important;

}
.Membership-res{
        letter-spacing: 0.5px;
        color: gray;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600 !important;
        font-size: 12px !important;

}
.Packages{

}
 /* Mobile view */
 /* FROM SM TO DOWN IS Max -width */
/* @include media-breakpoint-down(sm) { ... }
@include media-breakpoint-down(md) { ... }
@include media-breakpoint-down(lg) { ... }
@include media-breakpoint-down(xl) { ... }
@include media-breakpoint-down(xxl) { ... }
 */

/* FROM SM TO UP IS MIN-width */
/* @include media-breakpoint-up(sm) { ... }
@include media-breakpoint-up(md) { ... }
@include media-breakpoint-up(lg) { ... }
@include media-breakpoint-up(xl) { ... }
@include media-breakpoint-up(xxl) { ... }
 */
 @media (max-width: 575.98px) { 

.Mobile__Flows{
        display: block;
}
/* .Img-Bx-Donation{
        max-width: 330px;
        width: 330px;
        height: 200px;
        
} */
.Box-search{
        display: flex;
        flex-direction: column;
        align-items: start;

}
.Donation-Wrapper{
        display: flex;
        flex-direction: column;
        align-items: start;

}

.Donation-Name-Cont{
        display: flex;
        flex-direction: column;
        
}
.Flows{
        margin-bottom: 10px;
}

.Mobile__Se {
        display: block;
}

.Mobile__Search{
        margin-top: 5px;

}
.Transaction__Status{
        display: none;
}
.Transaction__Date{
        display: none;
}
.Transaction__Flow{
        display: none;
}





.Profile__Cont__Saves1{
        display: none;

}

}
      
@media (max-width: 991.98px) {               
                
.Transaction__Status{
        display: none;
}
.Transaction__Date{
        display: none;
}
.Transaction__Flow{
        display: none;
}



                                  
}

@media (max-width: 1200px) {
.Outflow__Inflow{
        width: 100%;
}

.Search__Search{
        width: 100%;
}
.Search__Search1{
        width: 100%;   
        margin-top: 10px;
}
                
.Flows{
        margin-bottom: 10px;
}
.Mobile__Search{
         margin-top: 5px;
}
.Mobile__Flows{
        display: block;
        margin-top: 5px;
}
.Mobile__Se {
        display: block;
        margin-top: 5px;
}




                         
}
  
