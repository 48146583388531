.cardings{
        border: 1px solid red;
        margin: 10px 0px;
        padding: 20px;
}

.Btu{
        background-color: green;
}

.Boxify{
        display: flex;
        gap: 1rem;
        margin: 10px;
        flex-flow: row wrap;
        transition: background-color 0.1s ease-in-out;
}

.Verified-Opp{
        background-color: #C6F6D5;
        color: #02664d;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600; 
        font-size: 11px !important;
        padding: 5px 10px;
        border-radius: 50px;
}

.boxx{
        width: 200px;
        height: 100px;
        background-color: blue;
        
}

      
 .UnitEntry{
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600; 
        font-size: 14px !important;
        margin-bottom: 10px;

 }

 .BankDetailsContainer{
     
        width: 250px;
 }



 .Steps{
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600; 
        font-size: 14px !important;
        background-color: #FECA27;
        border-radius: 50px;
        padding: 10px 15px;
        width: 100px;
        margin-bottom: 10px;

 }

 .BankDetailsWrapper{
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600; 
        font-size: 14px !important;
        margin-bottom: 10px;
 }

 .CleeQ24-BankDetails{
        display: flex;
        align-items: center;
        justify-content: space-between;
 }

 .CleeQ24-AcccNumber{
        color: gray;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600; 
        font-size: 14px !important;

 }
.CleeQ24-Account-Num{
        
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600; 
        font-size: 16px !important;

}



.Shop-Cont{
        background-color: green;
}
.BTN-inactive{
        border: none;
        
        /* background-color: #F7AD27; */
        background-color: rgb(233, 233, 233);
        padding: 5px 10px;
        border-radius: 50px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 1px;  
        font-size: 14px !important;
        color: black;
        }



.ButtonBTNChanger{
        border: none;
        background-color: #F7AD27;
        padding: 5px 10px;
        border-radius: 50px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 1px;  
        font-size: 14px !important;
        color: black;
        }


.active{
      
          border: none;
          border-radius: 5px;
          font-family: 'Urbanist', sans-serif;
          font-weight: 600;
          letter-spacing: 1px;  
          font-size: 13px !important;
          color: #F7AD27;
          text-decoration: none;
          cursor: none;
          pointer-events: none; /* Disable hover effect */
          
 }

 .inactive{
        color: gray;
        text-decoration: none;
        font-family: 'Urbanist', sans-serif;
          font-weight: 600;
          letter-spacing: 1px;  
          font-size: 13px !important;
         

 }

 .inactive:hover{
        /* background-color: rgb(247, 245, 245); */
        border: none;
        /* padding: 5px 10px; */
        border-radius: 5px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 1px;  
        font-size: 13px !important;
        color: #F7AD27;
        /* width: 200px; */
        text-decoration: none;


}


.SideCover{
            /* background-color: rgb(247, 245, 245); */
            border: none;
            padding: 10px 40px;
            border-radius: 5px;
            font-family: 'Urbanist', sans-serif;
            font-weight: 600;
            letter-spacing: 1px;  
            font-size: 13px !important;
            color: rgb(53, 52, 52);
            text-decoration: none;
            margin-bottom: 10px;



}

.SideShift{
        margin-left: 10px;
}

.SideLink{
             /* background-color: rgb(247, 245, 245); */
             border: none;
             /* padding: 5px 10px; */
             border-radius: 5px;
             font-family: 'Urbanist', sans-serif;
             font-weight: 600;
             letter-spacing: 1px;  
             font-size: 13px !important;
             color: rgb(53, 52, 52);
             /* width: 200px; */
             text-decoration: none;


}


        .SideBarBTNInactive{
             
                /* background-color: rgb(247, 245, 245); */
                border: none;
                padding: 5px 10px;
                border-radius: 5px;
                font-family: 'Urbanist', sans-serif;
                font-weight: 600;
                letter-spacing: 1px;  
                font-size: 13px !important;
                color: rgb(53, 52, 52);
                cursor: pointer;
                /* width: 200px; */
                text-decoration: none;



                }
        
        
        
        .SideBarBtnActive{
                border: none;
                /* background-color: #F7AD27; */
                padding: 5px 10px;
                border-radius: 5px;
                font-family: 'Urbanist', sans-serif;
                font-weight: 600;
                letter-spacing: 1px;  
                font-size: 13px !important;
                color: #F7AD27;
                /* width: 200px; */
                }
        
        

.SideBarContG{
        display: flex;
        align-items: center;
        gap: 1rem;

}

.navbtnContainers{
        margin-top: 20px;
        margin-bottom: 20px;

}
.navbtnContainers30{
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

}

.InfoBTN{
        border: none;
        background-color: #F7AD27;
        padding: 5px 10px;
        border-radius: 50px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 1px;  
        font-size: 14px !important;
        color: black;
}

.BTNWrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;

}
    
.Parent{
        /* outline:5px solid red; */
        padding: 1rem;
        

        
}

.Main-Side-Bar{
        display: flex;
        gap: 1rem;
        align-items: start;
        flex-wrap: wrap;
        overflow: hidden;
        height: 100vh;
      
        /* position: relative; */

}

/* .Main-Side-Bar > :first-child */
aside{
 
        flex-basis: 300px;
        
        /* flex-grow: 9999; */

        
      
       

}

/* .Main-Side-Bar > :last-child */






/* body {
        overflow: hidden;
        height: 100vh;
      }
      main {
        overflow-y: auto;
      }
      ul {
        list-style: none;
      }
      li {
        color: #fff;
      } */
      .aside1 {
        /* border-right:  1px solid #ededed;
        background: white;
        max-width: 15%;
        width: 100%;
        padding: 2rem;
        flex-grow: 9999;
        flex-basis: 250px; */
      
        /* flex-grow: 9999; */
        /* flex: 2 0 10%;
         */
        /* ;
        /* width: 250px; */
       
        
      }
      .wrapper {
        display: flex;
        justify-content: space-between;
        height: 100%;
      }
    
      .wrapper_inner {
        width: 90%;
        margin: 0px auto;
      }


.input-group60{
        display: flex;
}
      .Duration-Time-CountDown{
        background-color: #FECA27;
        padding: 10px 15px;
        border-radius: 50px;

      }

      .Official-Notice{
        background-color: #FECA27;
        padding: 10px 15px;
        border-radius: 50px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 1px;  
        font-size: 14px !important;

      }

      .Invest-Equity-Box{
        display: flex;
        flex-direction: column;
        height: 100px;
        justify-content: space-between;
        padding: 20px;
        background-color: black;
        border-radius: 10px 20px;

      }
      .Invest-Equity-Heading{
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 1px;  
        font-size: 14px !important;
        color: rgb(189, 189, 189);

      }
      .Invest-Equity{
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 1px;  
        font-size: 15px !important;
        color: white;

      }




      .Duration-Time-CountDown-button{
        background-color: black;
        padding: 10px 15px;
        border-radius: 50px;
        color: #FECA27;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 1px;  
        font-size: 14px !important;

      }



.MainDash{
        flex-basis: 500px;
        flex-grow: 1;
        height: 100vh;
        overflow-y: auto;
        -ms-overflow-style: auto;
        top: 0;
        /* flex-grow: 1;
  
        flex-basis: 200px;
        height: 100vh; */
        /* top: 0; */

        background-color: #F4F4F7;
        overflow-y: auto;
        width: 85%;
        padding-bottom: 200px;
}

.HomeDash{
        flex-basis: 500px;
        flex-grow: 1;
        height: 100vh;
        overflow-y: auto;
        -ms-overflow-style: auto;
        top: 0;
        overflow-y: auto;
        width: 85%;
        padding-bottom: 200px;
}



.FBackground{
        /* background-color: red; */
        width: 400px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 1px;  
        font-size: 14px !important;
        padding-top: 20px;
        color: gray;
}

.SelectCont{
        color: gray;
}


.NavBarSetting{
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        background-color: #F4F4F7;
        z-index: 999;
        padding: 20px 0px;
        margin-bottom: 20px;
}
.SubDashHeadings{
        font-size: 18px !important;
        color: black;
        margin-bottom: 20px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 1px;  
       

}
.FistHeadingCont{
        display: flex;  
        align-items: center; 
        justify-content: space-between;
        margin-bottom: 50px;
     
        background: rgb(255,202,39);
        background: linear-gradient(90deg, rgba(255,202,39,1) 0%, rgba(255,255,255,1) 65%, rgba(255,202,39,1) 100%);
        padding: 0px 10px 0px 30px ;
        border-radius: 20px;
        height: 100px;
        
        /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */

}



.RevDepContainer{
       
        /* height: 416px; */
}
.DashHead{
        font-size: 18px !important;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 800;
        letter-spacing: 0.5px;  
}
.DashHead20{
        font-size: 14px !important;
        color: gray;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;  
}
.AddNewBtn{
        border-radius: 50px;
        border: none;
        background-color: black;
        font-size: 15px !important;
        color: #F7AD27;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        padding: 20px 30px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}
.CBNDeposit{
        /* height: 150px; */
        border-radius: 15px;
        background-color: white;
}
/* 
.Ucontainer{
        display: flex;

} */
/* 
.Hey{
         height: 400px;
         display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: red;

} */


.TotalWrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;

}
.SlectDateYearCont{
        display: flex;
        align-items: center;
        justify-content: space-between;
        

}
.ArrroTContWrapper2{
        display: flex;
        flex-direction: column;
        gap: 1rem;
}
.CleeQRevBox{
       
    

        /* height: 300px; */
        display: flex;
        align-items: center;
        justify-content: space-between;
       
   
}

.UserHeadings{
        font-size: 15px !important;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: black;

}
.ArrroT{
        display: flex;
        align-items: center;
        gap: 0.3rem;

}
.AllContainer{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        /* align-items: center;
        justify-content: space-between; */
        /* gap: 1rem; */

}
.PackService{
        display: flex;
        flex-direction: column;
        gap: 1rem;

}
.ArrroTContWrapper{
        display: flex;
        align-items: center;
        gap: 1rem;

}



.ArrroTAndDate{
        display: flex;
        align-items: center;
        justify-content: space-between;

}
.UserViewAllCont{
        display: flex;
        align-items: center;
        gap: 1rem;
      
        max-width: 150px;
        width: 150px;

}
.UserViewAll{
        font-size: 12px !important;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: #F7AD27;
        
}

.ArrroTWrapper{
        display: flex;
        justify-content: space-around;
        
        align-items: center;

}
.ArrroTCont2{
        background-color: #F7AD27;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        padding: 2px;
        cursor: pointer;
        width: 50px;
        height: 50px;
       
       
}
.ArrroTCont40{
        background: none;
        outline: none;
        border: none;
        border-radius: 50px;
        background-color: #F7AD27;
        font-size: 12px !important;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: black;
        padding: 5px;
        width: 80px;
        
        
        
      
}

.ArrroTCont{
        background-color: #F7AD27;;
        display: flex;
        justify-content: center;
        border-radius: 50%;
        padding: 2px;
        cursor: pointer;
}


.ArrroTIcon{
        color:  gray;

}
.UserTargetMonth{
        font-size: 12px !important;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: #90979e;

}
.UserTargetSub{
        font-size: 15px !important;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: #07aa51;
        display: flex;
        gap: 1rem;
        align-items: center;

}
.UserTargetHeadings{
        font-size: 12px !important;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: #90979e;
        display: flex;
        justify-content: space-around;
        align-items: center;

}
.UserHeadingsSub{
        font-size: 30px !important;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: gray;

}
.StaTcONT{
      
        height: 145px;
        display: flex;
        flex-direction: column;
        justify-content: end;
       

}
.CleeQRevBox3{
        display: flex;
        flex-direction: column;
        gap: 4rem;
        cursor: pointer;
   
}

.CleeQRevBox50{
        display: flex;
        flex-direction: column;
        gap: 4rem;
       
   
}

.CleeQRevBox44{
        display: flex;
        flex-direction: column;
        gap: 10rem;
   
}
.CleeQRev{
        /* height: 250px; */
        border-radius: 15px;
        background-color: white;
}
.RevDepBox{
        display: flex;
        flex-direction: column;
        justify-content: space-between !important;
        gap: 1rem;
}
.DashContainerStat001{
        background-color: white;
        height: 50px;
        border-radius: 15px;

}
.DashContainerStat400{
        background-color: white;
        border-radius: 15px;
        margin-bottom: 20px;
        padding: 20px;
        font-size: 14px !important;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: gray;

}

.DashContainerStat40{
        background-color: white;
        /* height: 150px; */
        border-radius: 15px;

}
.DashContainerStat401{
        background-color: #F7AD27;
        /* height: 150px; */
        border-radius: 15px;

}

.InfloandOutflowTransactions{
        margin-top: 20px;
        margin-bottom: 50px;

}
.DashContainerStat30{
        background-color: white;
        height: 150px;
        /* border-radius: 15px; */
        

}
.DashContainerStat3{
        background-color: white;
        height: 416px;
        border-radius: 15px;
}
.DashContainerStat22{
    
        background-color: white;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

}
.DashContainerStat244{
        border-radius: 15px;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: white;
        
       
}

.UserStatusWrapper707{
        margin-top: 20px !important;
}
.StatAll{
        border-radius: 10px;
        height: 100px;
        background-color: white;
}
.IconHeadingCon{
        display: flex;
        align-items: center;
        gap: 1rem;

}
/* .Gone{
        display: flex;
        flex-direction: column;
        justify-content: space-between;

} */
.CustomerCntainer{
        /* background-color: blue; */
        height: 320px;
        border-radius: 15px;
        
       

}

.UserInfomat{
        font-size: 20px !important;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: gray;

}
/* .AccountHeaderWrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;


} */

.AccountHeaderWrapper30{
        display: flex;
        align-items: center;
        justify-content: space-between;


}
.AccountTypeHeading{
        font-size: 15px !important;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: black;

}

.TotalAmount{
        font-size: 20px !important;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: black;

}

.AccountVerifyContainer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;

}
.AccountVerify1{
        font-size: 15px !important;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: green;

}
.AccountVerify2{
        font-size: 15px !important;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: red;

}
.AccountVerify3{
        font-size: 15px !important;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: rgb(13, 108, 163);

}
.AccountVerify4{
        font-size: 15px !important;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: rgb(117, 78, 4);

}
.AccountVerify5{
        font-size: 15px !important;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: rgb(13, 30, 126);

}
.NameSub{
        font-size: 15px !important;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: gray;

}
.AccountTypeCont20{
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        margin-top: 40px;

}

.AccountTypeCont{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 40px;


}
.AccountControl{
        padding: 0px 0px 0px 0px;
}
/* .AccountHeaserUser{
        padding: 20px 0px;
} */
.DashContainerStat22022{
        background-color: white;
     
        /* border-radius: 10px; */
        /* margin-bottom: 200px; */
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

}

.UserInfoWrapper{
        /* background-color: white; */
        /* padding: 10px; */
        /* border-radius: 10px; */
        /* margin-top: 10px; */
        

}


.UserInfoWrapper2{
        /* background-color: blue; */
        /* padding: 10px; */
        /* border-radius: 10px; */
        /* margin-top: 10px; */
       
        

}

.AccountInfoCont{
      
        border: 2px solid #c2c2c4;
        /* border-radius: 20px; */
        /* margin-bottom: 50px; */
        /* padding: 20px; */
      
}

.pg{
      
        border: 2px solid #c2c2c4;
        /* border-radius: 20px; */
        margin-bottom: 50px;
        padding: 20px;
      
}
.AccountStatusWrappper{
        border: 2px solid #c2c2c4;
        /* border-radius: 20px; */
        margin-bottom: 10px;
        padding: 20px;

}




.DashContainerStat02{
        background-color: white;
        height: 200px;
        border-radius: 15px;

}
.DashContainerStat{
        background-color: white;
      
}


.DashContainerStat2{
        background-color: #F4F4F7;
        /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}

.TrasactionHeadings{
        margin-top: 30px;
}
.FooterTarget{
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }
footer{
        margin-top: auto;
    }


.Sidebarcont{
        position: fixed;
        top: 0;

}
/* .Children{
        outline:2px solid blue;
       

} */
.CliqueBar{
        position: fixed;
        top: 0;

      
} 

.TransactionContainerBox{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.5rem;

}
.TransactionStat{
        display: flex;
        align-items: center;
        justify-content: space-between;

}

.TransactionCount{
        display: flex;
        align-items: center;
        justify-content: space-between !important;

}

.TransactionStatusBox{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: none;
        background-color: #F4F4F7;
        padding: 5px 5px;
        width: 100%;
        height: 90px;

}
.inactiveTransactionBox{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: none;
        background-color: #F7AD27;
        padding: 5px 5px;
        width: 100%;
        height: 90px;

}
.TransactionCount20{
        font-size: 13px !important;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: gray;
        

}

.HeadingAndIcon{
        display: flex;
        align-items: center;
        justify-content: space-between !important;
}

.ioui{
        display: flex;
        /* align-items: center; */
        justify-content: space-between;

}

.WWalletAmtCountContainer{
        display: flex;
        align-items: center;
        justify-content: space-between !important;

}

.TableHeading{
        font-size: 20px !important;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: gray;
       

}

.TableHeadingContainer{
        margin-top: 30px;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between !important;

        
}




.Contribution-Amts-C{
        max-width:110px;
        width: 110px;
        display: inline-block;
        /* background-color: blue; */
     
        /* margin: 5px; */
}

.PictureBVN{
        background-color: rgb(228, 225, 225);
        height: 200px;
}

.PictureHeading{
        margin-top: 30px;
        margin-bottom: 10px;
}
.SecurityContainer{
        display: flex;
        align-items: center;
        justify-content: space-between;
}
.Verifications{
        border: none;
        background: none;
        font-size: 13px !important;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: gray;

}

.AccountAndNotCONT{
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
}

.UserInfomat{
        display: flex;
        align-items: center;
        /* gap: 1rem; */
        /* justify-content: space-between; */


}


/* INVESTMENT CSS */
.Invest-Dashboard{
        background-color: black;
        height: 150px;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;

}

.Invest-Dashboard40{
        background-color: black;
        height: 200px;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;
        gap: 2rem;

}

.Investor-Time-Container{
        display: flex;
        align-items: center;
}


.Invest-Dashboard25{
       
        padding: 20px;

}

.InvAmtHeading{
        font-size: 20px;
        color: gray;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.2px;
        display: flex;
        align-items: center;
        justify-content: space-between;

}

.InvAmtHeading002{
        font-size: 14px;
        color: gray;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.2px;
        display: flex;
        align-items: center;
        justify-content: space-between;

}
.Remaining{
        font-size: 13px;
        color: gray;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.2px;

}
.RemCont{
        display: flex;
        align-items: center;
        justify-content: space-between;
      
        font-size: 16px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.2px;

}


.NetProfitHeading20{
        font-size: 15px;
        color: #F7AD27;
        font-family: 'Urbanist', sans-serif;
        font-weight: 800;
        letter-spacing: 0.2px;

}


.InCont{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;

}
.InContWrapper{
        width: 50%;
        
}

.InContWrapper001{
        width: 50%;
        font-size: 16px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.2px;
}

.Invest-DashboardContainer{
        display: flex;
        justify-content: space-between;
        align-items: center;

}

.DashBoardContainer{
        display: flex;
        align-items: center;
        gap: 1rem;
      

}

.Inv-Display-Container{
        background-color: #f0f0f0;
        padding: 20px;
        border-radius: 20px;
        
}

.Inv-Display-Container-Details{
        font-size: 14px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.2px;
        margin-bottom: 10px;
}

.Inv-Display-Container-Payment{
        font-size: 15px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.2px;
        margin-bottom: 30px;
}

.Inv-Display-Container-Result{
        font-size: 15px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 800;
        letter-spacing: 0.2px;
}
.Inv-Name-Username-Cont{
        display: flex;
        align-items: center;
        max-width: 180px;
        width: 180px;
        /* display: inline-block; */
        /* margin-bottom: 10px; */
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        /* background-color: blue; */
       
}
.Inv-User-Cover{
        margin-left: 10px;
}
.Inv-Image-User{
        width: 40px;
        height: 40px;
        border-radius: 50%;

}
.Inv-Name-D{
        font-size: 12px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.2px;

}

.Inv-Username-D2{
        display: flex;
        align-items: center;
        font-size: 12px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 500;
        letter-spacing: 0.2px;
      

}


.InvestorsCont{
        background-color: white;
        padding: 20px 10px;
        border-radius: 15px;

}


.InvestActive{
       
        background-color: #F7AD27;
        padding: 5px 15px 5px 5px ;
        font-size: 12px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 500;
        border-radius: 50px !important;
       
        letter-spacing: 0.5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
}



.InvestInactive{
        
        background-color: #e7e7e7;
        padding: 5px 5px ;
        font-size: 12px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 500;
        border-radius: 50px !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 7px;
        letter-spacing: 0.5px;
        cursor: pointer;

}
.Inv-Amts{
        max-width:130px;
        width: 130px;
        display: inline-block;
   
       
       
       
     
        /* margin: 5px; */
}

.InvBar3{
        margin-bottom: 5px;
        background-color: #F7AD27;
        padding: 5px 15px 5px 5px ;
        font-size: 12px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 500;
        border-radius: 50px !important;
        letter-spacing: 0.5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        

}

.II{
        
       
        font-size: 12px;
        color: #F7AD27;
        font-family: 'Urbanist', sans-serif;
        font-weight: 500;
        letter-spacing: 0.5px;
        cursor: pointer;
        background-color: #b6b5b4;
        border-radius: 50px;
        padding: 5px 15px;
        /* max-width:80px;
        width: 80px; */
        display: inline-block;
      

}

.AA{
        background-color: #F7AD27;
        border-radius: 50px;
        padding: 5px 15px;
        /* max-width:100px; */
        /* width: 100px; */
        display: inline-block;
      

}


.Inv-ID4{
        /* max-width:70px;
        width: 70px;
        display: inline-block;
        padding-left: 14px; */
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #F4F4F7;
        padding: 5px 15px 5px 5px ;
        margin-bottom: 10px;
        border-radius: 50px;
      


   
        /* margin: 5px; */

}
.Inv-Eq{
        max-width:80px;
        width: 80px;
        display: inline-block;
      
        
     
       
       
     
        /* margin: 5px; */
}

.Inv-Status{
        max-width:80px;
        width: 80px;
        display: inline-block;
        padding-left: 14px;
        /* background-color: red; */
       
       
     
        /* margin: 5px; */
}


.Inv-Heading{
        font-size: 14px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;

}

.Inv-Result{
        font-size: 12px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 500;
        letter-spacing: 0.5px;
        display: flex;

}

.Inv-HeadingIcon{
        font-size: 12px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 500;
        letter-spacing: 0.5px;
        display: flex;

}



.InvBar{
        margin-bottom: 5px;
        /* display: flex;
        align-items: center;
        gap: 1rem; */

}





.InvContainer{
        background-color: white;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: space-between;
        height: 80px;
        padding: 10px 10px 10px 10px;

}

.InvHeading{
        font-size: 12px;
        color: gray;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        
}

.InvRes{
        font-size: 14px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;

}

.InvContainerWrapeper{
        display: flex;
        align-items: center;
        justify-content: space-between;


}
.InvSearchUser{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 2px solid  #F4F4F7;
        border-radius: 50px ; 
        padding: 10px 10px 10px 10px;
        margin-bottom: 20px;
     


}
.InvSearchUser56{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 2px solid  #F4F4F7;
        border-radius: 50px ; 
        padding: 10px 10px 10px 10px;
     
     


}
.InvestorsContHeading{
        font-size: 20px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        

}
.InvestorsContHeading2{
        font-size: 20px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin-bottom: 20px;
        margin-top: 20px;
        

}

.InvInpuSearchInput{
        width: 100%;
        /* width: 100%; */
        /* background-color: red; */
        border: none;
        height: 30px;
        color: gray;
        font-family: 'Urbanist', sans-serif;
        font-weight: 500;
        font-size: 15px;
        letter-spacing: 0.5px;
        border-radius: 10px;
        padding: 10px;    
}


.InvInpuSearchInput:focus {
        border: none;
        outline: none;    
  }
  .InvestorsContWrapperT{
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* background-color: red; */
        /* margin-bottom: 30px ; */
  }

  .InvDashContainerStat001{
        background-color: white;
        border-radius: 15px;

}
.Invest-Dashboard20{
        background-color: #F4F4F7;
        border-radius: 15px;
        padding: 10px 10px 10px 10px;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
     
}
.InvestDetails{
        display: flex;
        align-items: center;
        justify-content: space-between;

        

}

.InvestDetailsHeading{
        color: gray;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 13px;
        letter-spacing: 0.5px;
}

.EquityNumber{
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0.5px;
        display: flex;
        align-items: center;
        justify-content: space-between;

}

.TransCont{
        margin-top: 100px;
}

.InTransactionInfo{
        background-color: #F4F4F7;
        /* height: 150px; */
        border-radius: 15px;
        padding: 20px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 13px;
        letter-spacing: 0.5px;
        margin-bottom: 5px;

}

.InvestMentTransactionWrapper{
        /* background-color: #F4F4F7; */
        /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}

.FlowTransactions{
        background-color: white;
        /* height: 150px; */
        border-radius: 15px;

}
.EquityNumberSubCont{
        display: flex;
        align-items: center;
        justify-content: space-between;


}
.InvestorsContHeadingWrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;


}
.INTBTNCONTAINER{
        display: flex;
        align-items: center;
        justify-content: space-between;
         gap: 1rem;

}
.INVTBTN{
        background-color: #F7AD27;
        border: none;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 13px;
        letter-spacing: 0.5px;
        padding: 5px 10px;
        border-radius: 50px;

}

.AddNewBtnInvestor{
        border-radius: 50px;
        border: none;
        background-color: black;
        font-size: 15px !important;
        color: #F7AD27;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        padding: 10px 20px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}

.FlowWrapperInvet{
        display: flex;
        align-items: center;
        justify-content: space-between;
         gap: 1rem;
         margin-bottom: 20px;
        
        
}
.FlowNumberInv{
        font-size: 13px !important;
        color: gray;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;

}

.FlowWrapperInvetOutflow{
        font-size: 15px !important;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;

}

.InvetSubDashHeadings{
        font-size: 18px !important;
        color: gray;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin-top: 20px;

}

.InvDetailsCont{
        margin-bottom: 20px;
}

.DetailsHeadings{
        font-size: 13px !important;
        color: gray;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin-top: 5px;

}

.DetailsHeadingsName{
        font-size: 13px !important;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;

}
.DetailsHeadingsName3{
        font-size: 13px !important;
        color: gray;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin-top: 5px;

}

.DetailsHeadingsNamewRAPPER{
display: flex;
flex-direction: column;
justify-content: space-between;
height: 100px;
margin-bottom: 30px;
}

.InvBtnWrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 50px;
        margin-bottom: 20px;

}
.Member-Searchesbtn{
        background-color: black;
        color: #F7AD27;
        width: 100px;
        height: 50px;
        letter-spacing: 0.2px;
        font-size: 12px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        text-align: center;
        line-height: 50px;
        /* border-radius: 50%; */
        border-top-right-radius: 50px !important;
        border-bottom-right-radius: 50px !important;
        cursor: pointer;
        letter-spacing: 0.5px;
}
.Member-SearchesInput{
        color: black;
        height: 50px !important;
        font-size: 14px !important;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600 !important;
        border-top-left-radius: 50px !important;
        border-bottom-left-radius: 50px !important;
        letter-spacing: 0.5px;
}
.Member-Searches{
        display: flex;
        align-items: center;
        justify-content: space-between;
      
       
       

}
.Display-Container2{
        margin-top: 10px;
        margin-bottom: 100px;
        
}


.InvBar2{
        margin-bottom: 5px;
        background-color: #F4F4F7;
        padding: 5px 15px 5px 15px ;
        font-size: 12px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 500;
        border-radius: 50px !important;
        letter-spacing: 0.5px;
        display: flex;
        align-items: center;
        justify-content: space-between;

}

.CoContainer{
        /* margin-top: 5px; */
        margin-bottom: 5px;
}

.INV-ID{
        max-width:120px;
        width: 120px;
        display: inline-block;
        /* background-color: red; */
      

}
.Amount-Amts{
        max-width:120px;
        width: 120px;
        display: inline-block;
        /* background-color: red; */

}

.Inv-Flow{
        max-width:120px;
        width: 120px;
        display: inline-block;
       

}

.InvAmtCont{
       
        background-color: white;
        padding: 5px 20px ;
        font-size: 12px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 500;
        border-radius: 50px !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 7px;
        letter-spacing: 0.5px;

}

.InvestICO{
        color: green;
        font-size: 20px;
}

.IA{
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.5px;
        color: #b18604;

        /* background-color: #e7e7e7; */

}

.ICONINACTIVE{
        background-color: #F4F4F7;
        cursor: pointer;
        color: #444546;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.5px;
        padding: 2px 10px;
        border-radius: 50px;

}

.InvestmentDashboardHeader{
        display: flex;  
        align-items: center; 
        justify-content: space-between;
        margin-bottom: 20px;
     
        /* background: rgb(255,202,39);
        background: linear-gradient(90deg, rgba(255,202,39,1) 0%, rgba(255,255,255,1) 65%, rgba(255,202,39,1) 100%);
         */
        border-radius: 20px;
       
        
        
}

.InvAmtHeadingResult{
        display: flex;  
        align-items: center; 
        justify-content: space-between;

}
.NetPro{
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.5px;

}

.ProFitAmount{
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.5px;

}

.BodyTop{
        background-color: black;
        z-index: -9999;
        height: 100px;
}

.WithdrawFunds{
        border-radius: 50px ;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.5px;
        padding: 5px 20px;
        background-color: #F7AD27;
        color:   black;
        border: none;


}

.IconReact{
        font-size: 20px;

}
.BenHeadings{
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0.5px;
        color: gray;
        /* margin-bottom: 20px; */

}
.BenHeadingsTittle{
        margin-bottom: 10px;

}
.INV-AccountDetails{
        
        margin-bottom: 20px;
        background-color: #F4F4F7;
        padding: 20px;
        border-radius: 10px;

}

.InvAmtHeadingWrapper{
        display: flex;  
        align-items: center; 
        justify-content: space-between;

}

.Settings-Cont{
        background-color: #F7AD27;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 13px;
        letter-spacing: 0.5px;
        color: black;
        padding: 3px 7px;
        border-radius: 50px;
        border: none;
}

.ExecutiveInvDashContainer{
        display: flex;  
        align-items: center; 
        justify-content: space-between;
        gap: 1rem;

}

.ExDashHead{
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 13px;
        letter-spacing: 0.5px;
        color: gray;
        padding: 1px 5px;
        border-radius: 50px;
        border: none;

}

.EquitySold{
        display: flex;  
        align-items: center; 
        justify-content: space-between;
       

}

.InvetSubDashHeadingsCover{
        display: flex;  
        align-items: center; 
        justify-content: space-between;

}

.AddNewBtnInvestorCont{
        display: flex;  
        align-items: center; 
        justify-content: space-between;
        gap: 1rem;

}








/* DASHBOARD FOR INVESTORS CSS */

.Investors-Dashboard-Body{
        /* padding: 50px 110px 0px 110px ; */
        
}

.Investors-Dashboard-Heading-Container{
        display: flex;  
        align-items: center; 
        justify-content: space-between;
        
}
.Investors-Dashboard-Heading20{
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 20px;
        letter-spacing: 0.5px;
        color: black;
        /* margin-top: 20px; */
}

.Investors-Dashboard-Heading{
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 20px;
        letter-spacing: 0.5px;
        color: black;
        margin-top: 20px;
}

.Investors-Dashboard-Overview{
        /* margin-top: 50px; */
}
.Investors-Transactions{
        margin-top: 10px;
        background-color: white;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 15px;
        letter-spacing: 0.5px;
        color: black;
        padding: 20px;
        border-radius: 20px;
}

.Investors-Transactions22{
        margin-top: 10px;
        background-color: white;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 15px;
        letter-spacing: 0.5px;
        color: black;
        padding: 10px;
        border-radius: 20px;

}
.Investors-Transactions300{
        margin-top: 10px;
        margin-bottom: 10px;
        background-color: white;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 15px;
        letter-spacing: 0.5px;
        color: black;
        padding: 10px;
        border-radius: 20px;
}


.Investors-Invested-Amount{
        background-color: black;
        height: 120px;
        box-shadow: rgba(7, 114, 221, 0.2) 0px 8px 24px;
        display: flex;  
        flex-direction: column; 
        justify-content: space-between;
        color: gray;
        padding: 20px;
        border-radius: 20px;

}
.Investors-Invested-Amount33{
        background-color: black;
        height: 200px;
        box-shadow: rgba(7, 114, 221, 0.2) 0px 8px 24px;
        display: flex;  
        flex-direction: column; 
        justify-content: space-between;
        color: gray;
        padding: 20px;
        border-radius: 20px;

}

.Investors-Invested-Amount22{
        background-color:white;
        height: 120px;
     
        display: flex;  
        flex-direction: column; 
        justify-content: space-between;
        color: gray;
        padding: 20px;
        border-radius: 20px;

}



.Investors-Invested-Heading{
        display: flex;  
        align-items: center; 
        justify-content: space-between;
        

}
/* .Investors-Amount-Heading{
        font-family: 'Urbanist', sans-serif;
        font-weight: 800;
        font-size: 14px;
        letter-spacing: 0.5px;
        color: #F7AD27;

} */

.Investors-Amount-Heading{
        font-size: 13px;
        color: #F7AD27;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.2px;
        display: flex;

}
.Investors-Amount-Heading30{
        font-size: 13px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 800;
        letter-spacing: 0.2px;
        display: flex;

}

.DurationCover{
        display: flex;
        align-items: center;
        justify-content: space-between;
}
.DurationCover-Time{
        display: flex;
        flex-direction: column;
}

.Duration-Time{
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 17px;
        letter-spacing: 0.5px;
        color: black;
        
}

.Duration-Time-Show{
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 17px;
        letter-spacing: 0.5px;
        color: rgb(59, 59, 59);

}

.Investors-Amount{
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 17px;
        letter-spacing: 0.5px;
        color: rgb(185, 184, 184);
        display: flex;
        gap: 0.3rem;
       

}

.Investors-Referals-Container-Intro{
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.5px;
        color: black;

}

.Investors-Referals-Code{
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.5px;
        color: black;
       

}

.Investors-Referals{
        display: flex;
        align-items: center;
        gap: 0.2rem;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.5px;
        color: black;
        background-color:#F7AD27;
        padding: 5px 10px;
        border-radius: 50px;
}

.Investors-Referals-Ico{
        font-size: 20px;
        cursor: pointer;
        color: gray;
}

.Investors-Referals-Container{
        display: flex;
        align-items: center;
        gap: 1rem;
        font-family: 'Urbanist', sans-serif;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.5px;
        color: black;
}
.Investors-Search{
       
        height: 60px;
        padding: 10px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 17px;
        letter-spacing: 0.5px;
        color: black;
}
.search-container{
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
}
.Investors-Search-BTN{
        height: 60px;
        padding: 10px 30px;
        background-color: black;
        border: none;

}
.Investors-Search-Icon{
        font-size: 30px;
        color: #F7AD27;
       

}

.Investors-Amount20{
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 15px;
        letter-spacing: 0.5px;
        color: rgb(185, 184, 184);
        display: flex;
        align-items: center;
        justify-content: space-between;
        

       

}


.Investors-Funding-Button{
        background-color: #F7AD27;
        color: black;
        padding: 10px 10px;
        border: none;
        border-radius: 50px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 20px;
        letter-spacing: 0.5px;
        display: flex;  
        align-items: center;
        gap: 1rem; 
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        /* width: 100px; */
}

.IconCont1{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #f5e6aa;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      
      
      }

      .AccIcon{
        font-size: 20px !important;
        color: #F7AD27;
        /* margin-bottom: 20px; */
       
      }
      .Display-Container2{
        margin-top: 10px;
        margin-bottom: 100px;
        
}

.Investment-Transaction-Info{
       
        background-color: #F4F4F7;
        padding: 5px 20px ;
        font-size: 12px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 500;
        border-radius: 50px !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 7px;
        letter-spacing: 0.5px;

}


.Inv-RResult-Amount{
        font-size: 12px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 500;
        letter-spacing: 0.5px;
        display: flex;

}




.Investors-TransactionRef{
        max-width:120px;
        width: 120px;
        display: inline-block;
        /* background-color: red; */
      

}

.Invested-Amts{
        max-width:120px;
        width: 120px;
        display: inline-block;
        /* background-color: red; */

}

.Invested-Names{
        max-width:150px;
        width: 150px;
        display: inline-block;
        /* background-color: red; */

}


.Inv-Equity{
        max-width:80px;
        width: 80px;
        display: inline-block;
        
     
       
       
     
        /* margin: 5px; */
}

.Inv-Bonus{
        max-width:80px;
        width: 80px;
        display: inline-block;
        
     
       

}
.Inv-ROI{
        max-width:140px;
        width: 140px;
        display: inline-block;
          
        /* margin: 5px; */
}

.Inv-Duration{
        max-width:140px;
        width: 140px;
        display: inline-block;

}

.Inv-ROI{
        max-width:130px;
        width: 130px;
        display: inline-block;

}

.Inv-Date{
        max-width:120px;
        width: 120px;
        display: inline-block;
        /* background-color: red; */

}

.Inv-Maturity-Date{
        max-width:120px;
        width: 120px;
        display: inline-block;
        /* background-color: red; */

}
.Investment-Date{
        max-width:120px;
        width: 120px;
        display: inline-block;
}

.LinkClasss{
        text-decoration: none;
        font-size: 13px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
     
}
.LinkClasss:hover{
        text-decoration: none;
        font-size: 13px;
        color: gray;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
     
}

.Inv-Investor{
        max-width:100px;
        width: 100px;
        display: inline-block;

}
.Inv-Referrer{
        max-width:100px;
        width: 100px;
        display: inline-block;

}

.Inv-Referrer1{
        max-width:100px;
        width: 100px;
        display: inline-block;
}
.Investment-Amount{
        max-width:120px;
        width: 120px;
        display: inline-block;
        /* background-color: red; */

}
.Inv-Maturity-Status{
        max-width:80px;
        width: 80px;
        display: inline-block;

}


.INVRemaining{
        font-size: 12px;
        color: #F7AD27;
        font-family: 'Urbanist', sans-serif;
        font-weight: 500;
        letter-spacing: 0.5px;

}


.Spreader{
        margin-top: 100px;
}

.InvVestorsCoporation{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 50px;
        margin-bottom: 20px;

}

.TransactionCont{
        display: flex;
        align-items: center;
        gap: 0.3rem;
        /* justify-content: space-between; */
        /* font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 15px; */
        /* margin-bottom: 20px; */

}

.User-Headings{
         font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 15px; 

}

.Invwrapper_inner {
        /* width: 90%; */
        margin: 20px auto;
      }

.Investment-Transaction-Info50{
       
        background-color: #F4F4F7;
        padding: 5px 20px !important;
        font-size: 12px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 500;
        border-radius: 50px !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 7px;
        letter-spacing: 0.5px;

}



.Investor-Accounts{
        /* max-width:70px;
        width: 70px;
        display: inline-block;
        padding-left: 14px; */
        /* display: flex;
        align-items: center;
        justify-content: space-between; */
        background-color: #F4F4F7;
        padding: 5px 5px 5px 5px ;
        margin-bottom: 10px;
        border-radius: 50px;
        color: black;
      


   
        /* margin: 5px; */

}

.AccountHeading{
        font-size: 20px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin-bottom: 10px;
        

}

.Set-Bonus{
        font-size: 15px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        background-color: #F7AD27;
        border: none;
        border-radius: 50px;
        padding: 10px 15px ;

}

.Investment-Advice-Icon{
        font-size: 20px; 
}

.Investment-Advice-Cont{
        background-color: #f0c370;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border-radius: 50px;
}

.Investment-Advice{
        /* background-color: #f7eedf;
        padding: 20px;
        font-size: 20px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px; */
        display: flex;
        align-items: center;
        gap: 1rem; 
        /* border-radius: 20px; */
}

.Investment-Advice4{
        background-color: #f7eedf;
        padding: 10px 20px;
        font-size: 15px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.5rem;
        border-radius: 20px;
}


.III{
        
       
        font-size: 12px;
        color: #F7AD27;
        font-family: 'Urbanist', sans-serif;
        font-weight: 500;
        letter-spacing: 0.5px;
        cursor: pointer;
        border-radius: 50px;
        padding: 5px 15px;
        display: inline-block;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: black;
      

}



.AAA{
        background-color: #F7AD27;
        border-radius: 50px;
        padding: 5px 15px;
        /* max-width:100px; */
        /* width: 100px; */
        display: inline-block;
        color: black;
        display: flex;
        align-items: center;
        justify-content: space-between;

      

}

.Investor-Names{
        font-size: 13px;
        color: black;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        letter-spacing: 0.5px;

}

.Investor-UserNames{
        font-size: 12px;
        color: gray;
        font-family: 'Urbanist', sans-serif;
        font-weight:600;
        letter-spacing: 0.5px;

}

.Investor-NamesCont{
        margin-bottom: 20px;
        margin-top: 20px;
}

.InvestorsContWrapperT30{
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* background-color: red; */
        /* margin-bottom: 30px ; */
  }

.PersonalHeadings{
        font-size: 15px;
        color: gray;
        font-family: 'Urbanist', sans-serif;
        font-weight:600;
        letter-spacing: 0.5px;

}

.CleeQ24-LogoInv{
        display: flex;
        align-items: center;
        gap: 1rem;

}

.Invest-Dashboard202{
        background-color: #F4F4F7;
        border-radius: 15px;
        padding: 10px 10px 10px 10px;
      
        display: flex;
        flex-direction: column;
        justify-content: space-between;
     
}


.BInstructions{
        font-size: 12px;
        color: gray;
        font-family: 'Urbanist', sans-serif;
        font-weight:600;
        letter-spacing: 0.5px;

}

.InvBtnWrapper40{
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-top: 50px;
        margin-bottom: 20px;

}

.TermsAndConditions{
        margin-top: 50px;
        margin-bottom: 20px;

}



.IPassOTP{
        border-radius: 50px ;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.5px;
        padding: 5px 10p;
        background-color: #F4F4F7;
        color: gray;
        border: none;
        cursor: none;

}

.PassBtn{
        border-radius: 50px ;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.5px;
        padding: 5px 10p;
        background-color: black;
        color: #F7AD27; 
        border: none;

}


.EditDetails{
        
       
        background-color: #ffffff;
     
      

}

.LangDisplay{

        display: flex;
        align-items: center;
        

}

.Lang{
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.5px;
}


.CleeQSticky{

        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: 99999;
        /* background-color: #0A235B; */
        background-color: white;
}


.Nav-Bar{
        /* max-width: 1100px;
        width: 1100px;
        margin: 0 auto; */
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        /* background-color: red; */
        padding: 20px 0px 20px 00px;
       
       
        
           

}

.CleeQLogo{
        color: white;
}


.CleeQAuth{
        display: flex;
        align-items: center;
        gap: 1rem;
        color: gray;
}


.ClickLink{
        text-decoration: none;
        color: black;
        cursor: pointer;
        font-size: 13px !important;
        position: relative;
        text-decoration: none;
        padding: 10px 0px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 800;
        background: none;
        border: none;
        letter-spacing: 0.5px;
 
}    
     
      .ClickLink:hover{
        text-decoration: none;
        color: #F7AD27; 
        cursor: pointer;
        font-size: 13px !important;
        font-family: 'Urbanist', sans-serif;
        font-weight: 800;
        letter-spacing: 0.5px;
      
}
      

      .ClickLink::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 4px;
        border-radius: 4px;
        background-color:  #F7AD27; 
        bottom: 0;
        left: 0;
        transform-origin: right;
        transform: scaleX(0);
        transition: transform .3s ease-in-out;
        
      }
      
      .ClickLink:hover::before {
        transform-origin: left;
        transform: scaleX(1);
      }

      .SignUpAndLoginContianer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;

}

.SignIn-LogIn-Btn{
        color: black;;
        border-radius: 50px;
        background: none;
        background-color: #F7AD27; 
        border: none;
        padding: 10px 15px;
        font-family: 'Urbanist', sans-serif;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.5px;
       
      }

.SignIn-LogIn-Btn:hover{
        color: rgb(202, 202, 202);
        border-radius: 50px;
        background: none;
        background-color:#F7AD27; 
        border: none;
        padding: 10px 15px;
        font-family: 'Urbanist', sans-serif;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.5px;
      }

.Invest-Btn-Btn{
        color: black;;
        border-radius: 50px;
        background: none;
        background-color: #F7AD27; 
        border: none;
        padding: 10px 15px !important;
        font-family: 'Urbanist', sans-serif;
        font-size: 16px;
        font-weight: 800;
        letter-spacing: 0.5px;
        /* width: 350px; */
      }

.Invest-Btn-Btn:hover{
        color: rgb(202, 202, 202);
        border-radius: 50px;
        background: none;
        background-color:#F7AD27; 
        border: none;
        padding: 10px 15px !important;
        font-family: 'Urbanist', sans-serif;
        font-size: 16px;
        font-weight: 800;
        letter-spacing: 0.5px;
      }

.Investors-Home-Body{
        padding: 50px 110px 0px 110px ;
        /* margin-top: 150px; */
        /* background: green; */
        
}

.Investors-Home-Body2{
        padding: 50px 110px 0px 110px ;
        /* margin-top: 150px; */
        /* background-color: red; */
        
}

.Investors-Home-Body202{
        /* padding: 0px 110px 0px 110px ; */
        /* margin-top: 150px; */
        /* background-color: red; */
        
}
.Investors-Home{
       
display: flex;  
flex-direction: column; 
justify-content: space-between;
height: 450px;

/* padding-top: 60px; */
padding: 60px 0px 60px 0px ;


}

.Investors-Home-Heading{
        color: black;
font-size: 40px !important;
font-weight: 900;
letter-spacing: 0.5px;
font-style: normal;
font-family: "Nunito", sans-serif;

}

.Investor-Img{
        background-color: none;
}


.Investors-Home3{
       

        height: 400px;
        color: black;
        font-size: 40px !important;
        font-weight: 900;
        letter-spacing: 0.5px;
        font-style: normal;
        font-family: "Nunito", sans-serif;
        /* padding: 100px 0px 0px 0px; */
        }

.Investors-Count{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
}
.Inv-Cover{
        border: 1px solid rgb(223, 222, 222);
        border-radius: 20px;
        padding: 30px;
        height: 500px;
}
.Investors-Count-Heading{
       
        color: gray;
        font-size: 20px !important;
        font-weight: 600;
        letter-spacing: 0.5px;
        font-family: 'Urbanist', sans-serif;

}
.Investors-Count-Sun{
        color: black;
        font-size: 20px !important;
        font-weight: 600;
        letter-spacing: 0.5px;
        font-family: 'Urbanist', sans-serif;
        
}
.Inv-Cover1{
        border: 1px solid rgb(223, 222, 222);       
        border-radius: 20px;
        padding: 30px;
        height: 650px;
        /* margin-bottom: 100px; */
}
.Type-Container{
        margin-top: 20px;
}

.Type-Details-Wrapper{
        border: 3px solid rgb(187, 185, 185);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: space-between;
        padding: 10px;
}

.Type-Details-Wrapper-Headings{
        font-family: 'Urbanist', sans-serif;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: black;

}
.Type-Details-Wrapper-Sub{
        font-family: 'Urbanist', sans-serif;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: gray;

}
.Investors-Heading60{
        color: black;
        font-size: 30px !important;
        font-weight: 600;
        letter-spacing: 0.5px;
        font-style: normal;
        font-family: "Nunito", sans-serif;

}
.Investors-Heading{
                color: black;
                font-size: 12px !important;
                font-weight: 400;
                letter-spacing: 0.5px;
                font-style: normal;
                font-family: "Nunito", sans-serif;

                }
                .Investors-Heading22{
                        color: black;
                        font-size: 14px !important;
                        font-weight: 800;
                        letter-spacing: 0.5px;
                        font-style: normal;
                        font-family: "Nunito", sans-serif;
        
                        }
                .Investors-Heading20{
                        color: black;
                        font-size: 13px !important;
                        font-weight: 800;
                        letter-spacing: 0.5px;
                        font-style: normal;
                        font-family: "Nunito", sans-serif;
        
                        }
.feedback-carousel-container {
        display: flex;
        overflow: hidden;
        }
      
.feedback {
        width: 100%;
        flex-shrink: 0;
        transition: transform 500ms ease-in-out;
        }
      
.feedback.active {
        transform: translateX(-100%);
        }
      
/* .LandImg02{
        width: 100%;
        max-width: 100%;
    
        
        
        
        } */

        /* .SoleraLandingImg{
                width: 100%;
                height: 450px;
                border-radius: 0px 10px 10px 0px;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                
                
                } */
                
.Investors-Returns{
        font-family: 'Urbanist', sans-serif;
        font-size: 30px;
        font-weight: 800;
        letter-spacing: 0.5px;
        color: gray;
        margin-top: 50px;

}
.Investors-Intro{
        font-family: 'Urbanist', sans-serif;
        font-size: 20px;
        font-weight: 800;
        letter-spacing: 0.5px;
        color: gray;

}
.custom-select {
       
     
        border: 1px solid #ccc; /* Add border for better visibility */
        border-radius: 4px; /* Add rounded corners */
        background-color: #fff; /* Set background color */
        color: #333; /* Set text color */
        max-height: 150px; /* Set max height to make options scrollable */
  overflow-y: auto; /* Add scrollbar when options exceed max height */
  width: 100%;
  height: 60px;
  color: gray;
  font-family: 'Urbanist', sans-serif;
  font-weight: 600; 
  font-size: 15px !important;
  padding-left: 10px;
      }
      

/* Style the options */
.custom-select option {
        background-color: #fff; /* Set background color */
        color: #333; /* Set text color */
        padding: 8px; /* Add padding for better spacing */
        margin: 4px 0; /* Add margin to the options */
      }
      
      /* Style the options on hover */
      .custom-select option:hover {
        background-color: #007bff; /* Change background color on hover */
        color: #fff; /* Change text color on hover */
      }
      
      .fullWidthForm {
        width: 100%;
        max-height: 150px; /* Set max height to make options scrollable */
        overflow-y: auto; /* Add scrollbar when options exceed max height */
              
      }

.fullWidthFormInput{
        width: 100%;
        height: 60px;
        color: gray;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600; 
        font-size: 15px !important;
        padding-left: 10px;
     
       
        

 }
 .fullWidthFormInput:focus{
        outline: none;

 }
.Invest-Options{
        font-family: 'Urbanist', sans-serif;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: gray;
        /* padding: 330px !important;
        background-color: #02664d !important;
        text-align: center;
        margin: 300px !important; */
       
        
}

.Investors-Heading-Ico{
        display: flex;
        align-items: center;
        gap: 1rem;
}

.ContactIco{
        font-size:20px;
}

.InvestBody-CleeQ{
        margin-top: 150px;
        margin-bottom: 300px;

}

.CleeQBody{
        /* min-height: 100vh; */
        display: flex;
        flex-direction: column;
        /* background-color: #0A235B; */
        /* height: 550px; */
        margin-top: 100px;
        margin-bottom: 300px;
       
        
      }

      .CleeQBody1{
        /* min-height: 100vh; */
        /* background-color: green; */
        /* height: 600px; */
        margin-top: 200px;
        margin-bottom: 100px;
        
      }
      
      

      .CleeQBody2{
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        background-color: orange;
        /* height: 500px; */
      }

  /* Style the select element */
.form-select {
        width: 200px; /* Set the width */
        padding: 10px; /* Add padding */
        border: 1px solid #ccc; /* Add border */
        border-radius: 5px; /* Add border radius */
        background-color: #f8f9fa; /* Set background color */
        font-size: 16px; /* Set font size */
        color: #333; /* Set text color */
        cursor: pointer; /* Set cursor style */
      }
      
      /* Style the options container */
      .form-select option {
        padding: 10px; /* Add padding */
      }
      
      /* Style the selected option */
      .form-select option:checked {
        background-color: #007bff; /* Change background color */
        color: #fff; /* Change text color */
      }
      
      /* Style hover effect on options */
      .form-select option:hover {
        background-color: #e9ecef; /* Change background color on hover */
      }
      









      
      .image-slider {
        display: flex; /* Display images in a row */
        overflow-x: auto; /* Enable horizontal scrolling */
        scroll-behavior: smooth; /* Smooth scrolling */
      }
      
      .image-slider img {
        width: 300px; /* Set width of each image */
        height: auto; /* Maintain aspect ratio */
        margin-right: 10px; /* Add margin between images */
      }
      

