  /* On screens that are 600px wide or less, the background color is olive */
  @media screen and (max-width: 600px) {

        .HeaderContainer{
          display: block;
          font-size: 20px !important;
          font-family: 'Urbanist', sans-serif;
          color: #183153;
          font-weight: 500;

        }

        .Home-Investment-Container{
                margin-top: 50px;
        }

        .CleeQ-Nav-List{
                display: none;
        }

        .paddy{
                padding: 0px 20px 0px 20px ;
        }

       .Footer-Const{
        margin-top: 100px;
       }

       .Investors-Home {
        height: 400px;

        /* background-color: red; */

       }

       .Investors-Intro{
        font-size: 18px !important;

       }

       .Investors-Home-Heading{
        color: black;
        font-size: 30px !important;
        font-weight: 900;
        letter-spacing: 0.5px;
        font-style: normal;
        font-family: "Nunito", sans-serif;
}

.SignIn-LogIn-Btn{
        color: black;;
        border-radius: 50px;
        background: none;
        background-color: #F7AD27; 
        border: none;
        padding: 10px 15px;
        font-family: 'Urbanist', sans-serif;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0.5px;
       
      }
      .Nav-Bar-Logo{
        height: 20px;
        width: 100px;

    }
 
.SignIn-LogIn-Btn:hover{
        color: rgb(202, 202, 202);
        border-radius: 50px;
        background: none;
        background-color:#F7AD27; 
        border: none;
        padding: 5px 10px;
        font-family: 'Urbanist', sans-serif;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0.5px;
      }
            
      }

            
      /* On screens that are 992px wide or less, the background color is blue */
      @media screen and (max-width: 992px) {
        .CleeQ-Nav-List{
                display: none;
        }

 
      }