.UserFooter{
      
        width: 100%;
       
        color: white;
        text-align: center;
      
        padding: 10px;
        margin-top: 150px;
       
        
}

.Footer__Img{
        width: 20px;
        height: 20px;
}

.Footer__Note{
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 11px;
        color: gray;
        letter-spacing: 0.5px;
        margin-bottom: 5px;

}
