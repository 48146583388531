.CleeQ24__Logo{
  width: 140px;
  height: 30px;
}


.SideLogo{
  margin-top: 20px;
  margin-bottom: 60px;
margin-left: 40px;
}
.Clique24__Loog{
        width: 180px;
        height: 40px;
      }

.Clique24__Logo__User{
        width: 180px;
        height: 40px;
      }

.Log__Img__Modal1{
        width: 120px;
        height: 30px;
}

.LogoPosition{
 padding: 40px;
  text-align: center;
}