@media (max-width: 500px) {
        .wrapper {
          flex-direction: column;
        }
        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        
      }
      
      /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
        .example44 {background: red;}

        .PersonalInfoContainer{
                display: flex;
                flex-direction: column;
                gap: 1rem;
                justify-items: start;
                 
        }

        .EmailCont{
                text-align: start;
        }
        .NavBarContainer1{
                display:block;


        }
        .SideBarResponsive{
                display:none;
        }

        .SideDrawer{
                width: 400px;
        }
        .MenuSideBar{
                border: none;
                color: gray;
        }

        /* .Investors-Dashboard-Body{
                padding: 50px 0px 0px 0px !important;
               
                
        } */

        .Investors-Referals-Container-Intro{
                display: none;
        }

        .Investors-TransactionRef{
              
               
                display: none !important;
              
              }
        .Inv-Date{
                display: none !important;
        
        }
        .Inv-Equity{
                display: none !important;
              
        }

        .Investment-Amount{
                display: none !important;
        
        }

        .Inv-Referrer{
                display: none !important;
        
        }

      
        .Investment-Date{
                display: none !important;
        }

        .Inv-Investor{
                display: none !important;
        }

        .Invested-Names{
                display: none !important;
        }
  
      }
      
      /* Small devices (portrait tablets and large phones, 600px and up) */
      @media only screen and (min-width: 600px) {
        .example44 {background: green;}
        /* .PersonalInfoContainer{
                align-items: start;
                display: flex;
                justify-items: start;
                flex-direction: column;
                gap: 1rem;
                
        } */

        .EmailCont{
                text-align: start;
        }
        .SideBarResponsive{
                display:none;
        }
        .NavBarContainer1{
                display:block;


        }

        .MenuSideBar{
                border: none;
                color: gray;
        }
        /* .Investors-Dashboard-Body{
                padding: 50px 0px 0px 0px !important;
              
                
        } */
        .Investors-Referals-Container-Intro{
                display: none;
        }
        .Investors-TransactionRef{
              
               
                display: none !important;
              
              }
        
              .Inv-Date{
                display: none !important;
        
        }


}
      /* Medium devices (landscape tablets, 768px and up) */
      @media only screen and (min-width: 768px) {
        .example44 {background: blue;}
        .PersonalInfoContainer{
                align-items: start;
                display: flex;
                justify-items: start;
                flex-direction: column;
                gap: 1rem;
                
        }

        .EmailCont{
                text-align: start;
        }

        .NavBarContainer1{
                display:none;

        }
        .NavBarContainer1{
                display:block;


        }

        .MenuSideBar{
                border: none;
                color: gray;
        }
        /* .Investors-Dashboard-Body{
                padding: 50px 0px 0px 0px !important;
               
                
        } */
        .Investors-Referals-Container-Intro{
                display: none;
        }
        .Investors-TransactionRef{
              
               
                display: none !important;
              
              }
        

      } 
      
      /* Large devices (laptops/desktops, 992px and up) */
      @media only screen and (min-width: 992px) {
        .example44 {background: orange;}
        .PersonalInfoContainer{
                display: flex;
                flex-direction: row;
               
                
                
        }
        .EmailCont{
                text-align: end;
        }

        .NavBarContainer1{
                display:none;

        }
        .NavBarContainer1{
                display:none;


        }

        .MenuSideBar{
                border: none;
                color: gray;
        }

        .SideBarResponsive{
                display:block;
        }
        .Investors-TransactionRef{
              
               
                display: none !important;
              
              }
              .Investors-Referals-Container-Intro{
                display: block;
        }

        .Inv-Date{
                display: none !important;
        
        }
       
        /* .Investors-Dashboard-Body{
                padding: 50px 0px 0px 0px !important;
               
                
        } */
      } 

   
      
      /* Extra large devices (large laptops and desktops, 1200px and up) */
      @media only screen and (min-width: 1400px) {
        .example44 {background: pink;}

        .NavBarContainer1{
                display:none;

        }
        .Investors-TransactionRef{
              
               
                display: block !important;
              
              }
              .Investors-Referals-Container-Intro{
                display: block;
        }

        .Inv-Date{
                display: block !important;
        
        }
       
      }
    