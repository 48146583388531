.Search__paginate{
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: 'Urbanist', sans-serif;
        font-weight: 400 !important;
        font-size: 12px !important;
        color: gray;
        letter-spacing: 0.5px;  

}

.Search__paginate-Pre{
        cursor: pointer;
}
.Pag{
        background-color: red;
}
@media (max-width: 575.98px) { 

        .Pag{
                background-color: yellow;
                width: 100%;
                display: flex;
        }
      
        }