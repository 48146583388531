

/* On screens that are 992px wide or less, the background color is blue */
@media screen and (max-width: 1600px) {

  
  .CleeQBody2 {
    background-color: gray;
    color: green;
  }

   .Investors-Home-Body{
    padding: 0px 0px 0px 0px ;
    background-color: red;

    
} 




.Investors-Home{
       
  display: flex;  
  flex-direction: column; 
  justify-content: space-between;
  height: 450px;
  color: black;
  
  font-size: 30px !important;
  font-weight: 900;
  letter-spacing: 0.5px;
  font-style: normal;
  font-family: "Nunito", sans-serif;
  /* padding-top: 60px; */
  padding: 0px 0px 0px 0px ;
  
  
  }


}





/* On screens that are 992px wide or less, the background color is blue */
@media screen and (max-width: 1400px) {

  
        .CleeQBody2 {
          background-color: yellow;
          color: green;
        }

  

  .Inv-Duration{
   display: block;

}



   
  
      
      }

      /* On screens that are 992px wide or less, the background color is blue */
@media screen and (max-width: 1200px) {

  
  .CleeQBody2 {
    background-color: orange;
    color: green;
  }

  .Invested-Amts{
    display: none;

  }
 


}

      
      
      /* On screens that are 992px wide or less, the background color is blue */
      @media screen and (max-width: 992px) {
        .CleeQBody2 {
          background-color: blue;
          color: green;
        }
        .StatusCover{
                display: flex;
                flex-direction: column;
                
        }
        .Wallet-Initials{
          display: none;
        }

        .Wallet-Expected{
          display: none;

        }

        .Wallet-Flow{
          display: none;

        }
 
      .Wallet-Date{
        display: block;
      }
      .Wallet-Ref{
        display: none;

      }
      .Invested-Amts{
        display: none;
    
      }
     
 
      }
      
      /* On screens that are 600px wide or less, the background color is olive */
      @media screen and (max-width: 600px) {

        .InvestBody-CleeQ2{
          /* background-color: red; */
          /* margin-top: 310px; */

        }

        .CleeQBody2 {
          background-color: olive;
          color: black;
        }

        .StatusCover{
                display: flex;
                flex-direction: column;

        }

        .Wallet-Initials{
          display: none;
        }

        .Wallet-Expected{
          display: none;

        }

        .Wallet-Flow{
          display: none;

        }
 
      .Wallet-Date{
        display: none;
      }
      .Wallet-Ref{
        display: none;

      }

      .Investment-Mobile{
        display: block;

      }
  
  
.Investors-Dashboard-Overview{
 
  margin-bottom: 20px;
  display: block;
}

.Investors-Invested-Amount33{
  background-color: black;
  height: 200px;
  box-shadow: rgba(7, 114, 221, 0.2) 0px 8px 24px;
  display: flex;  
  flex-direction: column; 
  justify-content: space-between;
  color: gray;
  padding: 20px;
  border-radius: 20px;

}



      }