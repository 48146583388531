
.paddy{
        min-height: 100vh; 
        display: flex;
        flex-direction: column; 
        /* overflowY: "scroll";
        overflow-y: scroll; */
}
    .maddy{
        margin-top: auto;
    }
  .Footer-Const{
        margin-top: 200px;
       }
    .Nav-Bar-Logo{
        height: 25px;
        width: 120px;

    }

    .Logo-Footer{
        height: 20px;
        width: 100px;
        margin-bottom: 10px;

    }


    .Investors-Intro-Ico{
        font-size: 30px;
    }
 
    
.HeaderContainer{

        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: 999;
        /* background-color: #0A235B; */
        background-color: white;
}

 .Home-Investment-Container{
        margin-top: 150px;
}
.Investors-Verified{
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-top: 20px;
}
.CleeQ-Nav-List{
        display: flex;
        align-items: center;
        gap: 1rem;
        color: gray;

}

.CleeQ-Options{
        height: 250px;
        border-radius: 10px;
        /* background-color:  #F7AD27; */
        background: rgb(246,230,200);
        background: linear-gradient(90deg, rgba(246,230,200,1) 0%, rgba(247,173,39,1) 40%, rgba(207,150,48,1) 100%);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;
}
.CleeQ-Options1{
        height: 250px;
        border-radius: 10px;
        background-color:  black;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;
}


.CleeQ-Options-Icon-Cover{
        background-color: black;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
}
.CleeQ-Options-Icon-Cover1{
        background-color: gray;;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
}
.CleeQ-Options-Icon{
        font-size: 30px;
        color: #F7AD27;
}
.CleeQ-Options-Icon1{
        font-size: 30px;
        color: black;
}

.CleeQ-Options-Read-More{
        font-size: 12px;
        font-weight: 800px;
        border: none;
        border-radius: 20px;
        background-color: black;
        color: #F7AD27;
        padding: 2px 7px;
        font-family: 'Urbanist', sans-serif;
        width: 80px;
        

}
.CleeQ-Options-Read-More1{
        font-size: 12px;
        font-weight: 800px;
        border: none;
        border-radius: 20px;
        background-color: gray;
        color: white;
        padding: 2px 7px;
        font-family: 'Urbanist', sans-serif;
        width: 80px;
        letter-spacing: 0.6px;
        

}

.CleeQ-Options-More-Cover{
        display: flex;
        flex-direction: column;
        /* height: 70px; */
        gap: 1rem;
        /* background-color: red; */

}
.CleeQ-Options-More-Cover1{
        display: flex;
        flex-direction: column;
        /* height: 70px; */
        gap: 1rem;
        /* background-color: red; */

}
.CleeQ-Options-Heading{
        font-size: 25px;
        font-weight: 600px;
        color: black;
        font-family: 'Urbanist', sans-serif;

}
.CleeQ-Options-Heading1{
        font-size: 25px;
        font-weight: 600px;
        color: gray;
        font-family: 'Urbanist', sans-serif;

}
.InvestmentNote{
        color: black;
        font-size: 11px !important;
        font-weight: 400;
        letter-spacing: 0.5px;
        font-family: 'Urbanist', sans-serif;
        /* margin-top: 20px; */
        /* margin-bottom: 20px; */
        /* width: 450px;
        max-width: 450px; */

}