.Clique__Sti{
        color: gray !important;
        text-decoration: none;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 11px;
        /* margin-right: 10px; */
        cursor: pointer;
        letter-spacing: 0.5px;
      
      
      }
      .Settings__Cont{
        display: flex;
        align-items: center;

        gap: 1rem;

    }

    .NavWrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* padding: 10px 100px 0px 100px ; */
       
    }




.SetCont{
  display: flex;
  align-items: center;
  gap: 0.5rem;
 
}
      .ProfilePix{
        width: 40px;
        height: 40px;
      }
      .Hello__Sur{
       font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 15px;
        letter-spacing: 0.5px;
        color: black !important;
      }
      .Hello__Sur1{
        font-family: 'Urbanist', sans-serif;
         font-weight: 600;
         font-size: 20px;
         letter-spacing: 0.5px;
         color: black !important;
       }
      .Settings__Not{
        margin-right: 20px;
        cursor: pointer;
        letter-spacing: 0.5px;
      }
      .NavBarIcons{
        color: #F7AD27;
        cursor: pointer;
        
        
      }
   

          /* .Clique__Sti{
            margin-right: 10px;

          } */

          .NavBar__Img{
              width: 35px;
              height: 35px;
              margin-right: 10px;
              cursor: pointer;
          }

          .Pro__Img{
                width: 35px;
                height: 35px;  
          }
          
          .User__Img__Settings{
              width: 200px;
              height: 200px;
              border-radius: 50px;
              cursor: pointer;
          
          }


          /* .DashBoardContainer{
            display: flex;
            align-self: center;
            gap: 1rem;
          } */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {


  .MobileView__Display__None{
        display: block;
  }
}
         