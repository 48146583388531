.confirm-button-class {
      
  border: none !important; 
  background-color: black !important; 
  color: #ffca27 !important;
  padding: 10px 10px 10px 10px !important;
  width: 120px;
  text-align: center;
  border-radius: 50px !important;
  letter-spacing: 0.5px;
  font-family: 'Urbanist', sans-serif;
  font-weight: 600 !important;
  font-size: 13px !important;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.title-class {
  font-size: 17px !important;
  letter-spacing: 0.5px;
  font-family: 'Urbanist', sans-serif;
  font-weight: 400 !important;
 
}


.icon-class {
  font-size: 20px !important;
}
.text-class{
  font-size: 20px !important;
  letter-spacing: 0.5px !important;
  font-family: 'Urbanist', sans-serif !important;
  font-weight: 400 !important;

}



.confirm-button-class .swal2-icon svg {
  width: 12px !important;
  height: 12px !important;

}

.swal2-actions .swal2-confirm {
  border: none !important; 
  background-color: black !important; 
  color: #ffca27 !important;
  padding: 10px 10px 10px 10px !important;
  width: 120px;
  text-align: center;
  border-radius: 50px;
  letter-spacing: 0.5px;
  font-family: 'Urbanist', sans-serif;
  font-weight: 600 !important;
  font-size: 13px !important;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
 

}

.swal2-actions .swal2-cancel {
  border: none !important; 
  background-color: black !important; 
  color: #ffca27 !important;
  padding: 10px 10px 10px 10px !important;
  width: 120px;
  text-align: center;
  border-radius: 50px !important;
  letter-spacing: 0.5px !important;
  font-family: 'Urbanist', sans-serif !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
 

}

.swal2-confirm:focus, .swal2-cancel:focus {

  border: none !important;
  box-shadow: none !important;
  /* box-shadow: none !important;
  border: none !important;
  font-size: 18px !important;
  color: black;
  font-family: 'Urbanist', sans-serif;
  font-weight: 500 !important; */
}


.SideBarSuperHeadingsContainer{
  margin-bottom: 20px;
}